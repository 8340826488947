




































































import Vue from 'vue';
import Component from 'vue-class-component';
import { getOrFalse } from '@/utilities/helpers';

const Setup = Vue.extend({
	name: 'AppInput',
	inject: ['$validator'],
	$_veeValidate: {
		name() {
			return this.name;
		},
		value() {
			return this.value;
		},
	},
	props: {
		description: String,
		hideLabel: Boolean,
		inputId: String,
		inputClass: {
			default: 'np-input',
		},
		label: String,
		name: String,
		required: Boolean,
		value: {},
	},
});

@Component
export default class AppInput extends Setup {
	public get computedId() {
		return this.inputId || this.name;
	}

	public get computedClasses() {
		return [
			this.inputClass,
			{
				'np-input--dirty': getOrFalse(`${this.name}.dirty`, this.fields),
				'np-input--invalid': getOrFalse(`${this.name}.dirty`, this.fields) && getOrFalse(`${this.name}.invalid`, this.fields),
				'np-input--pristine': getOrFalse(`${this.name}.pristine`, this.fields),
				'np-input--touched': getOrFalse(`${this.name}.touched`, this.fields),
				'np-input--untouched': getOrFalse(`${this.name}.untouched`, this.fields),
				'np-input--valid': getOrFalse(`${this.name}.dirty`, this.fields) && getOrFalse(`${this.name}.valid`, this.fields),
			},
		];
	}

	public get listeners(): any {
		return {
			blur: (event: any) => this.$emit('blur', event.target.value),
			...this.$listeners,
			input: (event: any) => this.emitValue(event.target.value),
		};
	}

	public emitValue(value: any) {
		this.$emit('input', value);
	}
}
