import Service from '@/services/Service';
import Model from '@/models/Model';
import ParkingZone from '@/models/ParkingZone';
import RecurringGroup from '@/models/RecurringGroup';

export default class Recurring extends Model {
	public active: boolean = true;
	public active_count: number = 0;
	public airport_tax: number = 0;
	public alternate_payment: boolean = false;
	public balance: number = 0;
	public bill_type: number | null = null;
	public customer_id: number = 0;
	public fee: number = 0;
	public frequency: number = 1;
	public last_charged: string | Date | null = null;
	public last_invoiced: string | Date | null = null;
	public location: number = 0;
	public location_code: string = '';
	public min_parkers: number | null = null;
	public max_parkers: number | null = null;
	public next_charge: string | Date | null = null;
	public parking_zone: number | null = null;
	public payment_method: null | string = null;
	public promo_id: number | null = null;
	public prorate_date: string | Date | null = null;
	public reminder_email: boolean = true;
	public renewal_email: boolean = true;
	public sales_tax: number = 0;
	public stall_number: string | null = null;
	public start_date: string | Date | null = null;
	public status: string = 'active';
	public stop_date: string | Date | null = null;
	public tos_date: string | Date | null = null;
	public waitlist_overflow: boolean = false;

	public name: string = '';
	public parkingZone: ParkingZone | null = null;
	public recurringGroup: RecurringGroup | null = null;

	// Frontend properties
	public total: number = 0;
	public locationObj: any | null = null;
	public codeID: string | null = null;
	public locationName: string = '';

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		this.total = this.fee;
	}

	/**
	 * Sets the 'stop date' to the current day
	 */
	public stop(date:string) {
		this.stop_date = (new Date()).toISOString();
		if (date > this.stop_date) {
			this.stop_date = date;
		}
	}
}
