import Model from './Model';

/**
 * Stall model (used for associating stalls to vehicles)
 */
export default class Stall extends Model {
	public auto_key: number | null = null;
	public prefix: string | null = null;
	public stall: number | null = null;
	public stall_w_prefix: string | null = null;
	public status: string | null = null;
	public customer: number | null = null;
	public ticket: number | null = null;
	public vehicle: number | null = null;
	public parking_zone: number | null = null;
	public license: string | null = null;
	public name: string | null = null;
	public location: number | null = null;


	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}
}
