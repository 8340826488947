import { getOrArray, stringOrDefault } from '@/utilities/helpers';

export default class QuoteDetails {
	public breakdown: any[];
	public calendar_day: boolean;
	public days: number;
	public discount: number;
	public discount_rate: number;
	public fpp_per_day: number;
	public fpp_per_dollar: number;
	public hours: number;
	public no_coupons: boolean;
	public no_discounts: boolean;
	public no_fpp: boolean;
	public no_mandatory: boolean;
	public no_services: boolean;
	public rate: number;
	public rate_history_id: number;
	public rate_type: number;
	public require_services: boolean;
	public weeks: number;

	public constructor(data: any = {}) {
		this.breakdown = getOrArray('breakdown', data);
		this.calendar_day = data.calendar_day || false;
		this.days = +data.days || 0;
		this.discount = +data.discount || 0;
		this.discount_rate = +data.discount_rate || 0;
		this.fpp_per_day = +data.fpp_per_day || 0;
		this.fpp_per_dollar = +data.fpp_per_dollar || 0;
		this.hours = +data.hours || 0;
		this.no_coupons = data.no_coupons || false;
		this.no_discounts = data.no_discounts || false;
		this.no_fpp = data.no_fpp || false;
		this.no_mandatory = data.no_mandatory || false;
		this.no_services = data.no_services || false;
		this.rate = +data.rate || 0;
		this.rate_history_id = +data.rate_history_id || 0;
		this.rate_type = stringOrDefault('advanced', data.rate_type);
		this.require_services = data.require_services || false;
		this.weeks = +data.weeks || 0;
	}
}
