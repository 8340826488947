import { arrayOrNull, stringOrDefault, stringOrNull } from '@/utilities/helpers';

export default class Service {
	public airport_taxable: boolean;
	public blackout_dates: null | string;
	public class: string;
	public description: string;
	public notes: null | string;
	public price: number;
	public sales_taxable: boolean;
	public service: number;
	public service_id: number;
	public vehicle_types: any[] | null;
	public ws_description: null | string;
	public ws_notes: null | string;
	public ws_vehicle_info: boolean;

	public constructor(data: any = {}) {
		this.airport_taxable = !!data.airport_taxable;
		this.blackout_dates = stringOrNull(data.notes);
		this.class = stringOrDefault('wash', data.class);
		this.description = stringOrDefault('', data.description);
		this.notes = stringOrNull(data.notes);
		this.price = +data.price || 0;
		this.sales_taxable = !!data.sales_taxable;
		this.service = +data.service || 0;
		this.service_id = +data.service_id || 0;
		this.vehicle_types = arrayOrNull(data.vehicle_types);
		this.ws_description = stringOrNull(data.ws_description);
		this.ws_notes = stringOrNull(data.ws_notes);
		this.ws_vehicle_info = !!data.ws_vehicle_info;
	}
}
