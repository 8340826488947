import Service from '@/services/Service';
import Account from '@/models/Account';
import Model from '@/models/Model';

import ACHAccount from '@/models/ACHAccount';
import CreditCard from '@/models/CreditCard';
import Group from '@/models/Group';
import MetaFields from '@/models/MetaFields';
import ParkingZone from '@/models/ParkingZone';
import RecurringGroup from '@/models/RecurringGroup';
import Recurring from '@/models/Recurring';
import Vehicle from '@/models/Vehicle';
import Stall from '@/models/Stall';
import { mapModels } from '@/utilities/helpers';
import { FormatAddress } from '@/utilities/formatters';

export default class Customer extends Model implements Account {
	public active: boolean = true;
	public additionalFields: { [prop: string]: string } = {};
	public address1: string | null = null;
	public address2: string | null = null;
	public number_of_passengers: number | null = null;
	public balances: Array<{ location: string, id: number, balance: number }> = [];
	public code: string | number = 0;
	public customer: number = 0;
	public credit_cards: CreditCard[] = [];
	public cell_phone: string | null = null;
	public city: string | null = null;
	public confirm_email: string = '';
	public company: number | null = null;
	public company_name: number | null = null;
	public company_admin: boolean = false;
	public company_obj: Group | null = null;
	public date_added: string | Date = new Date();
	public discount: number | null = null;
	public email: string = '';
	public email_receipt: boolean = false;
	public ff_airline: string | null = null;
	public ff_number: number | null = null;
	public fpp: number | null = 0;
	public first_name: string = '';
	public last_name: string = '';
	public loyalty_1: string | null = null;
	public loyalty_2: string | null = null;
	public loyalty_3: string | null = null;
	public misc_a: string | null = null;
	public misc_b: string | null = null;
	public new_password: string = '';
	public newsletter_opt_in: boolean = false;
	public no_fpp_earnings: boolean = false;
	public notes: string | null = null;
	public other_id: string | null = null;
	public parking_tax_exempt: boolean = false;
	public parking_zones: ParkingZone[] = [];
	public promo_code: string | null = null;
	public rate: number | null = null;
	public services_tax_exempt: boolean = false;
	public state: string | null = null;
	public stalls: Stall[] = [];
	public tax_exempt: boolean | string = false;
	public usedTempPassword = false;
	public work_phone: string | null = null;
	public mand_option: number | null = null;
	public zip: string | null = null;
	public ach_accounts: ACHAccount[] = [];
	public recurring: Recurring[] = [];
	public recurring_promo: null | number | string = null;
	public recurringGroup: RecurringGroup | null = null;
	public recurringOption: ParkingZone | RecurringGroup | null = null;
	public paymentMethod: null | string = null;
	public text_opt_in: boolean = false;
	public uid: string | null = null;
	public vehicles: Vehicle[] = [];
	public needs_activated: boolean | string = false;
	public meta_fields: { [prop: string]: MetaFields } = {};

	// Frontend or registration-specific properties
	public captcha_token: string | null = null;
	public code_id: string | null = null;
	public registration_type: string = '';
	public reservation_id: number | null = null;
	public view: string = 'customer';

	public constructor(data: any = {}) {
		super(data);
		this.setup(data);
	}

	public setup(data: any = {}) {
		this.initialize(data);

		this.ach_accounts = mapModels(ACHAccount, data.ach_accounts) as ACHAccount[];
		this.company_obj = new Group(data.company_obj || {});
		this.credit_cards = mapModels(CreditCard, data.credit_cards) as CreditCard[];
		this.recurring = mapModels(Recurring, data.recurring) as Recurring[];
		this.vehicles = mapModels(Vehicle, data.vehicles) as Vehicle[];
		this.stalls = mapModels(Stall, data.stalls) as Stall[];
	}

	public get account_id(): number {
		return this.customer || 0;
	}

	public get account_other_id(): string {
		return this.other_id || '';
	}

	public get account_type(): string {
		return 'customer';
	}

	public get address(): string {
		return FormatAddress({
			address1: this.address1 || undefined,
			address2: this.address2 || undefined,
			city: this.city || undefined,
			state: this.state || undefined,
			zip: this.zip || undefined,
		});
	}

	public get attn(): string {
		return '';
	}

	public get balance(): number {
		return this.balances.reduce((balance, item) => {
			balance += item.balance;
			return balance;
		}, 0);
	}

	public get existing(): boolean {
		return this.customer > 0;
	}

	public get has_vehicles(): boolean {
		return this.vehicles.length > 0;
	}

	public get has_stalls(): boolean {
		return this.stalls.length > 0;
	}

	public get name(): string {
		return `${this.first_name} ${this.last_name}`.trim();
	}

	public get phone(): string | null {
		return this.cell_phone;
	}

	public set phone(value: string | null) {
		this.cell_phone = value;
	}

	public get primaryCard(): CreditCard | null {
		return this.credit_cards.find((card) => card && card.primary) || null;
	}

	public get secondaryCard(): CreditCard | null {
		return this.credit_cards.find((card) => card && !card.primary) || null;
	}

	public get primaryACH(): ACHAccount | null {
		return this.ach_accounts.find((account) => account && account.primary) || null;
	}

	public get secondaryACH(): ACHAccount | null {
		return this.ach_accounts.find((account) => account && !account.primary) || null;
	}

	public cancelRecurring(location: number, date: string) {
		const recurring = this.recurring.find((rec) => rec.location === location);

		if (!recurring) {
			return false;
		}

		recurring.stop(date);
		return this.save(['recurring']);
	}

	public emailLoyaltyVoucher() {
		return Service.get('emailLoyaltyVoucher');
	}

	public getAccounts(params: any) {
		return Service.post('getAccounts', { params });
	}

	public getBalance(location: number | null): number {
		if (!location) {
			return this.balance;
		}

		const balance = this.balances.find((item) => +item.id === location);
		return balance ? balance.balance : 0;
	}

	public getInvoices(params: any, subLocation: any = null) {
		return Service.post('getInvoices', { params, subLocation });
	}

	public getReservations(params: any, subLocation: any = null) {
		return Service.post('getReservations', { params, subLocation });
	}

	public getTickets(params: any, subLocation: any = null) {
		return Service.post('getTickets', { params, subLocation });
	}

	public payBalance(amount: number, payment_type: string, subLocation: any = null) {
		return Service.post('payBalance', { amount, payment_type, subLocation });
	}

	/**
	 * Saves all of the data on the current model to the DB
	 * It's possible to pass in specific properties to update
	 * as well as pass in data to override on the model (useful for
	 * not updating the base model until the save is successful).
	 *
	 * If no `customer` value is set on this model, this will attempt to
	 * register the account.
	 *
	 * @param properties Properties to update
	 * @param data Data to override on the model (in [key => value] format)
	 * @returns True on success, false otherwise
	 */
	public async save(properties: string[] = [], data: any = {}) {
		data = Object.assign({}, this, data);

		const results = await Service.post('saveAccount', { data, properties });
		if (!results) {
			return true;
		}

		this.setup(results);
		return results;
	}

	public async validateEmail() {
		const results = await Service.post('validateEmail', { email: this.email });
		if (!results) {
			return true;
		}
		return results;
	}

	public saveACHAccount() {
		return this.save(['ach_accounts']);
	}

	public saveCreditCard() {
		if (this.primaryCard && this.primaryCard.deleted && this.secondaryCard) {
			this.secondaryCard.primary = true;
			this.primaryCard.primary = false;
		}
		return this.save(['credit_cards']);
	}

	public setACHAccount(ach: ACHAccount) {
		this.ach_accounts[0] = ach;
	}

	public setCreditCard(type: string, card: CreditCard) {
		type = type || 'primary';
		const index = this.credit_cards.findIndex((c) => c && ((c.primary && type === 'primary') || (!c.primary && type !== 'primary')));

		if (index >= 0) {
			this.credit_cards[index] = card;
		} else {
			card.primary = (type === 'primary');
			this.credit_cards.push(card);
		}
	}

	public updatePassword(oldPassword: string, newPassword: string) {
		return Service.post('updatePassword', { oldPassword, newPassword });
	}

	public updateRecurringMethod(paymentMethod: string, subLocation: number) {
		const recurring = this.recurring.find((rec) => rec.location === subLocation);

		if (!recurring) {
			return false;
		}

		recurring.payment_method = paymentMethod;
		return this.save(['recurring']);
	}

	public static login(login: string, password: string, location: string) {
		return Service.post('verifyLogin', { login, password, location });
	}

	public static lostPassword(email: string, location: number | string) {
		const customer = new Customer();
		customer.email = email;
		return Service.post('lostPassword', { email, location });
	}

	public static resendActivationCode(email: string, location: number | string) {
		const customer = new Customer();
		customer.email = email;
		return Service.post('resendActivationCode', { email, location });
	}

	public static activateAccount(email: string, code: string, location: number | string) {
		const customer = new Customer();
		customer.email = email;
		customer.code = code;
		return Service.post('activateAccount', { email, code, location });
	}

	public removeCompanyFromCustomer() {
		if (!this.company || !this.customer) {
			return false;
		}
		if (this.company_admin) {
			throw new Error('Cannot remove a customer that is an admin');
		}

		return Service.post('removeCompanyFromCustomer', { company_id: this.company, customer_id: this.customer });
	}
}
