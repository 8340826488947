import { format, isValid, parse } from 'date-fns';
import Vue from 'vue';

const formats: DateFormats = {
	DATE_FULL: 'MMMM D, YYYY',
	DATE_MED: 'MMM. D, YYYY',
	DATE_MYSQL: 'YYYY-MM-DD',
	DATETIME_MED: 'MMM. D, YYYY h:mm A',
	DATETIME_MYSQL: 'YYYY-MM-DD HH:mm:ss',
	DATETIME_SHORT: 'MM/DD/YYYY',
};

Vue.filter('dateTime', (value: string, outputFormat: string) => {
	if (Object.prototype.hasOwnProperty.call(formats, outputFormat)) {
		outputFormat = formats[outputFormat];
	}

	const date = parse(value);

	return isValid(date) ? format(date, outputFormat) : value;
});

interface DateFormats {
	DATE_FULL: string;
	DATE_MED: string;
	DATE_MYSQL: string;
	DATETIME_MYSQL: string;
	DATETIME_SHORT: string;
	[propName: string]: string;
}
