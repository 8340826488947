import Model from '@/models/Model';

export default class ReservationCoupon extends Model {
	public auto_key: number = 0;
	public reservation_id: number = 0;
	public coupon_id: number = 0;
	public min_days: number = 0;
	public amount: null | number = null;
	public days: null | number = null;
	public fpp: null | number = null;
	public tax_on_gross: boolean = false;
	public description: null | string = null;
	public created_dt: Date | string = new Date();
	public updated_dt: Date | string = new Date();
	public location: null | number = null;
}
