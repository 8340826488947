import Model from '@/models/Model';
import { mapModels, stringOrBlank } from '@/utilities/helpers';
import Option from '@/models/Option';
import QuoteDetails from '@/models/QuoteDetails';
import ReservationCoupon from '@/models/ReservationCoupon';
import ParkingZone from './ParkingZone';

export default class Quote extends Model {
	public airport_taxes_subtotal: number = 0;
	public balance_due: number = 0;
	public coupons: ReservationCoupon[];
	public days_redeemed: number = 0;
	public details: QuoteDetails;
	public due_at_lot: number = 0;
	public event_dates: string[] = [];
	public fpp: number = 0;
	public grand_total: number = 0;
	public index: number = -2;
	public is_event: boolean = false;
	public is_prepay: boolean = false;
	public mandatory_options: boolean = false;
	public name: string = '';
	public notes: string;
	public options: Option[];
	public location_information: [] = [];
	public options_subtotal: number = 0;
	public parking_net: number = 0;
	public parking_airport_tax: number = 0;
	public parking_rate_subtotal: number = 0;
	public parking_sales_tax: number = 0;
	public parking_taxes_subtotal: number = 0;
	public parking_subtotal: number = 0;
	public parking_zone: ParkingZone;
	public prepaid: number = 0;
	public prepay_amount: number = 0;
	public promo_code: string;
	public requires_validation: boolean = false;
	public sales_taxes_subtotal: number = 0;
	public savings: number = 0;
	public services_airport_tax: number = 0;
	public services_net: number = 0;
	public services_sales_tax: number = 0;
	public services_subtotal: number = 0;
	public services_taxes_subtotal: number = 0;
	public taxes_subtotal: number = 0;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		this.details = new QuoteDetails(data.details);
		this.coupons = mapModels(ReservationCoupon, data.coupons) as ReservationCoupon[];
		this.options = mapModels(Option, data.options) as Option[];
		this.notes = stringOrBlank(data.notes);
		this.promo_code = stringOrBlank(data.promo_code);
		this.parking_zone = new ParkingZone(data.parking_zone);
	}
}
