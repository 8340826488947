import Vue from 'vue';
import {
	ActionTree,
	GetterTree,
	Module,
	MutationTree,
} from 'vuex';
import Customer from '@/models/Customer';
import Payment from '@/models/Payment';
import Quote from '@/models/Quote';
import Reservation from '@/models/Reservation';
import Service from '@/models/Service';
import Vehicle from '@/models/Vehicle';
import ReservationService from '@/services/ReservationService';
import ReservationStep from '@/types/reservationStep';
import { FormatAddress } from '@/utilities/formatters';
import {
	cloneDeep,
	find,
	findIndex,
	get,
	getOr,
	getOrArray, getOrFalse,
	getOrNull,
	getOrString,
	getOrZero,
	isEmpty,
	isEqual,
	mapModels,
	urlParams,
} from '@/utilities/helpers';

const NP_DATA = NP_PLUGIN_DATA || {};
const initialQuote = NP_DATA.reservation ? NP_DATA.reservation.initialQuote : null;

interface State {
	availableServices: Service[];
	customerExists: boolean;
	dates: any;
	edit: boolean;
	initialQuote: number;
	noQuotes: boolean;
	noServices: boolean;
	options: any[];
	originalReservation: Reservation;
	quotes: Quote[];
	preload: boolean;
	reservation: Reservation;
	shortcodeAttrs: { [key: string]: string };
	step: ReservationStep;
	vehicle: Vehicle;
}

const findService = (
	service: Service,
	services: Service[],
) => find((item: Service) => service.service === item.service)(services);

const findServiceIndex = (
	service: Service,
	services: Service[],
) => findIndex((item: Service) => service.service === item.service)(services);

const State: State = {
	availableServices: [],
	customerExists: false,
	dates: {},
	edit: NP_DATA.reservation ? NP_DATA.reservation.edit : false,
	initialQuote: (initialQuote !== null && initialQuote >= 0) ? initialQuote : -1,
	noServices: true,
	originalReservation: new Reservation(),
	options: [],
	noQuotes: false,
	quotes: [],
	preload: false,
	reservation: new Reservation(NP_DATA.reservation ? NP_DATA.reservation.reservation : {}),
	step: ReservationStep.Dates,
	shortcodeAttrs: {},
	vehicle: new Vehicle(),
};

const Getters: GetterTree<State, StoreTypes.RootState> = {
	address(state, getters): string {
		return FormatAddress({
			address1: get('quote.parking_zone.address1', getters),
			address2: get('quote.parking_zone.address2', getters),
			city: get('quote.parking_zone.city', getters),
			state: get('quote.parking_zone.state', getters),
			zip: get('quote.parking_zone.zip', getters),
			country: get('quote.parking_zone.country', getters),
		});
	},

	customer(state): Customer {
		return getOr(new Customer(), 'reservation.customer', state);
	},

	customerExists(state): boolean {
		return getOrZero('reservation.customer_id', state) > 0 || state.customerExists;
	},

	customerFromLocation(state, getters, rootState, rootGetters): boolean {
		if (!get('customer', rootGetters.customer)) {
			return false;
		}

		const cust_location = (get('code', rootGetters.customer) || '').toString();

		const loc = `${state.reservation.location_code}-${state.reservation.location}`;
		const location = rootGetters.locationsMap[loc];

		return cust_location && location && location.custLocation.toString() === cust_location;
	},

	editLink(state, getters, rootState, rootGetters) {
		const basePath = rootState.paths.reservationEdit;
		const params = {
			id: state.reservation.uid,
			loc: rootGetters['location/locationCodeID'],
		};

		return `${basePath}?${urlParams(params)}`;
	},

	isEditable(state): boolean {
		return state.reservation.editable;
	},

	isExistingReservation(state): boolean {
		return !!state.reservation.auto_key;
	},

	hasServices(state, getters): boolean {
		if (state.availableServices.length > 0) {
			return true;
		}

		return !state.noServices;
	},

	quote(state): Quote {
		return getOr(new Quote(), 'reservation.quote', state);
	},

	noServicesOnRate(state, getters): boolean {
		return !!getOrNull('details.no_services', getters.quote);
	},

	services(state): Service[] {
		return getOr([], 'reservation.services', state);
	},

	step(state): ReservationStep {
		return state.step;
	},

	hasCustomer(state, getters) {
		return !isEmpty(getters.customer);
	},

	hasQuote(state, getters): boolean {
		return !isEmpty(getters.quote) && getters.quote.index > -2;
	},

	hasQuotes(state): boolean {
		return state.quotes && state.quotes.length > 0;
	},

	isModified(state): boolean {
		const reservation = JSON.parse(JSON.stringify(state.reservation));
		const originalReservation = JSON.parse(JSON.stringify(state.originalReservation));

		delete reservation.coupons;
		delete reservation.customer;
		delete reservation.options;
		delete reservation.quote;
		delete reservation.quoteRequest;
		delete reservation.payments;
		delete reservation.vehicle;
		delete originalReservation.coupons;
		delete originalReservation.customer;
		delete originalReservation.options;
		delete originalReservation.quote;
		delete originalReservation.quoteRequest;
		delete originalReservation.payments;
		delete originalReservation.vehicle;

		return !isEqual(reservation, originalReservation)
			|| getOrNull('quote.index', state.reservation) !== getOrNull('quote.index', state.originalReservation)
			|| getOrNull('quote.grand_total', state.reservation) !== getOrNull('quote.grand_total', state.originalReservation);
	},

	reprintLink(state, getters, rootState, rootGetters) {
		const basePath = rootState.paths.reservationEdit;
		const params = {
			action: 'voucher',
			id: state.reservation.uid,
			loc: rootGetters['location/locationNumber'],
			sub: state.reservation.location,
			reservation: state.reservation.reservation,
		};

		return `${basePath}?${urlParams(params)}`;
	},

	voucherLink(state, getters, rootState, rootGetters) {
		const basePath = rootState.paths.reservationBook;
		const params = {
			action: 'voucher',
			id: state.reservation.uid,
			loc: rootGetters['location/locationNumber'],
			sub: state.reservation.location,
			reservation: state.reservation.reservation,
		};

		return `${basePath}?${urlParams(params)}`;
	},

	zoneEmail(state, getters) {
		return get('quote.parking_zone.email', getters);
	},

	zoneName(state, getters) {
		return get('quote.parking_zone.name', getters);
	},

	zonePhone(state, getters) {
		return get('quote.parking_zone.phone', getters);
	},
};

const Mutations: MutationTree<State> = {
	addFpp(state, points: number) {
		Vue.set(state.reservation, 'fpp_redeemed', points);
	},

	addService(state, service: Service) {
		const services = getOrArray('reservation.services', state);
		const existingService = findService(service, services);

		if (!existingService) {
			state.reservation.services.push(service);
		}
	},

	clearQuote(state) {
		state.reservation.quote = new Quote();
	},

	clearQuotes(state) {
		state.quotes = [];
		state.step = 0;
	},

	clearPayments(state) {
		state.reservation.payments = [];
	},

	resetFpp(state) {
		state.reservation.fpp_redeemed = 0;
	},

	reloadReservation(state) {
		Vue.set(state, 'reservation', new Reservation(state.reservation));
	},

	removeService(state, service: Service) {
		const services = getOrArray('reservation.services', state);
		const existingService = findServiceIndex(service, services);

		if (existingService > -1) {
			state.reservation.services.splice(existingService, 1);
		}
	},

	setAvailableServices(state, payload: Service[]) {
		state.availableServices = [...payload];
		state.noServices = !payload.length;
	},

	setCustomer(state, payload: Customer) {
		state.reservation.customer = payload;
	},

	setCustomerExists(state, payload: boolean) {
		state.customerExists = payload;
	},

	setEndDate(state, payload: string) {
		state.reservation.end_date = payload;
	},

	setLocation(state, payload: number) {
		state.reservation.location_code = payload;
	},

	setInitialQuote(state, payload: number) {
		state.initialQuote = payload;
	},

	setNoQuotes(state, payload: boolean) {
		state.noQuotes = payload;
	},

	setOriginalReservation(state, payload?: Reservation) {
		if (!payload) {
			state.originalReservation = new Reservation(cloneDeep(state.reservation));
		} else {
			state.originalReservation = new Reservation(cloneDeep(payload));
		}
	},

	setPayment(state, payment: Payment) {
		state.reservation.setPayment(new Payment(payment));
	},

	setPromoCode(state, payload: string) {
		state.reservation.promo_code = payload;
	},

	setQuote(state, quote: Quote) {
		const reservation = new Reservation(state.reservation);
		reservation.setQuote(quote);

		if (getOrNull('details.no_services', quote)) {
			reservation.services = [];
		}

		Vue.set(state, 'reservation', reservation);
	},

	setQuotes(state: State, payload: Quote[]) {
		state.quotes = mapModels(Quote, payload) as Quote[];
	},

	setReservation(state: State, payload: Reservation) {
		state.reservation = new Reservation(payload);
	},

	setReservationSource(state: State, source = 'website') {
		state.reservation.source = source;
	},

	setStartDate(state, payload: string) {
		state.reservation.start_date = payload;
	},

	setStep(state, payload: ReservationStep) {
		state.step = payload;
	},

	setTextOptIn(state, payload: boolean = true) {
		state.reservation.text_opt_in = !!payload;
	},

	setVehicle(state, payload: Vehicle) {
		state.reservation.vehicle = payload;
	},

	setRewards(state, payload: any) {
		state.reservation.reward_card = payload.card;
		state.reservation.reward_cards_id = payload.id;
	},
};

const Actions: ActionTree<State, StoreTypes.RootState> = {
	getQuotes(
		{ commit, rootGetters, state },
		payload: any = {},
	) {
		const currentLocation = getOrNull('location/location', rootGetters);
		const allLocations = getOrNull('location/locations', rootGetters);
		const { multi_locations = allLocations } = payload;
		const { location = currentLocation } = payload;
		const { reservation = state.reservation } = payload;
		const { resRate = false } = payload;

		commit('setLoading', true, { root: true });

		if (reservation.quote.index < -1 && !resRate) {
			commit('setQuotes', []);
			commit('resetFpp');
		}

		return new Promise((resolve, reject) => {
			ReservationService.getQuotes(
				reservation as Reservation,
				location as Locations.Location,
				resRate,
				multi_locations as Locations.Location[],
			)
				.then((response: any) => {
					if (!response || !response.length) {
						// A successful response was return but it did not
						// include any quotes.
						commit('setNoQuotes', true);
						commit('setQuotes', []);
					} else if (reservation.quote.index >= -1 || resRate) {
						// We are re-quoting the reservation, most likely a
						// service or customer was changed, so immediately
						// set the Fpp quote.
						const [quote] = response;
						commit('setQuote', quote);
					} else {
						// We are getting all new quotes for the
						// reservation.
						commit('setNoQuotes', false);
						commit('setQuotes', response);
					}

					commit('setLoading', false, { root: true });
					resolve(response);
				})
				.catch((errors: any) => {
					commit('setErrors', errors, { root: true });
					commit('setLoading', false, { root: true });
					reject(errors);
				});
		});
	},

	saveReservation(
		{
			commit,
			dispatch,
			rootGetters,
			state,
		},
		payload: any = {},
	) {
		const currentLocation = getOrNull('location/location', rootGetters);
		const { location = currentLocation } = payload;
		const { reservation = state.reservation } = payload;

		return new Promise((resolve, reject) => {
			dispatch('startRequest', true, { root: true });

			ReservationService.saveReservation(reservation as Reservation, location as Locations.Location)
				.then((response) => {
					dispatch('successfulRequest', response, {
						root: true,
					});

					commit('setReservation', response);

					return resolve(response);
				})
				.catch((errors) => {
					dispatch('failedRequest', errors, { root: true });

					if (!reservation.auto_key) {
						commit('clearPayments');
					}

					return reject(errors);
				});
		});
	},

	getServices({
		commit, state, rootGetters,
	}): Promise<any> {
		const preFetchErrors = [];
		const location = getOrString('location/location.codeID', rootGetters);
		const start = getOrString('start_date', state.reservation);
		const end = getOrString('end_date', state.reservation);
		const vehicle = getOr(new Vehicle(), 'vehicle', state.reservation);
		const reservationId = getOrZero('reservation.auto_key', state);

		commit('setLoading', true, { root: true });

		if (!location) {
			preFetchErrors.push({
				msg: 'A location is required to load services.',
			});
		}

		if (!start || !end) {
			preFetchErrors.push({
				msg:
					'A check-in and checkout date is required to load services.',
			});
		}

		if (preFetchErrors.length) {
			commit('setErrors', preFetchErrors, { root: true });
			commit('setLoading', false, { root: true });

			return Promise.reject(preFetchErrors);
		}

		return ReservationService.getServices(
			location,
			start,
			end,
			vehicle,
			reservationId,
		)
			.then((response: any) => {
				commit('setAvailableServices', response);
				commit('setLoading', false, { root: true });

				return Promise.resolve(response);
			})
			.catch((errors: any) => {
				commit('setErrors', errors, { root: true });
				commit('setLoading', false, { root: true });

				return Promise.reject(errors);
			});
	},

	async validateCustomer(
		{ commit, dispatch },
		data: { customer: Customer; vehicle: Vehicle, location: string },
	) {
		dispatch('startRequest', true, { root: true });

		return ReservationService.validateCustomer(data.customer, data.vehicle, data.location)
			.then((response) => {
				dispatch('successfulRequest', response, { root: true });
				commit('setCustomerExists', getOrFalse('customer.existing', response));

				return Promise.resolve(response);
			})
			.catch((errors) => {
				dispatch('failedRequest', errors, { root: true });

				return Promise.reject(errors);
			});
	},

	async sendLookupEmail({ commit, dispatch }, email: string) {
		const submitMessage = 'If you have a reservation, you will receive an email with a link to your reservation.';
		dispatch('startRequest', true, { root: true });

		return ReservationService.sendLookupEmail(email)
			.then((response) => {
				dispatch('successfulRequest', response, { root: true });
				return Promise.resolve(submitMessage);
			})
			.catch((errors) => {
				dispatch('failedRequest', errors, { root: true });

				return Promise.reject(submitMessage);
			});
	},
};

export default {
	namespaced: true,
	state: State,
	getters: Getters,
	mutations: Mutations,
	actions: Actions,
} as Module<State, StoreTypes.RootState>;
