import { Store } from 'vuex';

const analyticsPlugin = (store: Store<any>) => {
	store.subscribe((mutation) => {
		switch (mutation.type) {
			case 'reservation/addService':
				store.dispatch('analytics/addService', mutation.payload);
				break;
			case 'reservation/removeService':
				store.dispatch('analytics/removeService', mutation.payload);
				break;
			case 'reservation/setQuote':
				store.dispatch('analytics/setQuote', mutation.payload);
				break;
			case 'reservation/setQuotes':
				if (Array.isArray(mutation.payload) && mutation.payload.length) {
					store.dispatch('analytics/displayQuotes', mutation.payload);
				}
				break;
			case 'reservation/setAvailableServices':
				store.dispatch('analytics/displayServices', mutation.payload);
				break;
			case 'reservation/setStep':
				store.dispatch('analytics/reservationStep', mutation.payload);
				break;
			case 'reservation/setReservation':
				store.dispatch('analytics/bookReservation', mutation.payload);
				break;
			default:
				break;
		}
	});
};

export default analyticsPlugin;
