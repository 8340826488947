import Model from '@/models/Model';
import Stall from '@/models/Stall';

export default class Vehicle extends Model {
	public color: null | string = null;
	public customer: number = 0;
	public deleted: boolean = false;
	public license: null | string = null;
	public make: null | string = null;
	public model: null | string = null;
	public notes: null | string = null;
	public other_id: null | string = null;
	public state: null | string = null;
	public timestamp: Date | string = new Date();
	public type: null | string = null;
	public vehicle: number = 0;
	public vin: null | string = null;
	public year: null | number | string = null;
	public stall: Stall[] = [];


	public constructor(data: any = {}) {
		super();
		this.initialize(data);
	}
}
