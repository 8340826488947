import Service from '@/services/Service';

export default new class LocationService {
	public fetchToken(location: string): Promise<string> {
		return Service.post('fetchToken', { location });
	}

	public fetchTerms(payload: { location: string, type?: string }): Promise<string> {
		return Service.post('fetchTerms', payload);
	}

	public fetchLoginMarketing(payload: { location: string }): Promise<string> {
		return Service.post('fetchLoginMarketing', payload);
	}
}();
