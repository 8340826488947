import { stringOrBlank, stringOrDefault, stringOrNull } from '@/utilities/helpers';

export default class Option {
	public option: number;
	public description: string;
	public gratuity: string;
	public price: number;
	public type: string;
	public airport_taxable: boolean;
	public sales_taxable: boolean;
	public notes: null | string;
	public overridable: boolean;
	public mandatory: boolean;

	public constructor(data: any = {}) {
		this.option = +data.option || 0;
		this.description = stringOrBlank(data.description);
		this.gratuity = stringOrDefault('none', data.gratuity);
		this.price = +data.price || 0;
		this.type = stringOrDefault('daily', data.type);
		this.airport_taxable = !!data.airport_taxable;
		this.sales_taxable = !!data.sales_taxable;
		this.notes = stringOrNull(data.notes);
		this.overridable = !!data.overridable;
		this.mandatory = !!data.mandatory;
	}
}
