import { format, parse } from 'date-fns';
import moment from 'moment'
import Model from '@/models/Model';
import Customer from '@/models/Customer';
import Payment from '@/models/Payment';
import Quote from '@/models/Quote';
import ReservationCoupon from '@/models/ReservationCoupon';
import Service from '@/models/Service';
import Vehicle from '@/models/Vehicle';
import {
	formatPhone, get, getOrString, getOrZero, join, mapModels, stringOrBlank, zipWith,
} from '@/utilities/helpers';

enum Status {
	active = 'active',
	canceled = 'canceled',
}

enum StringBool {
	'true',
	'false',
}

export default class Reservation extends Model {
	public address: string | null = null;
	public address2: string | null = null;
	public allow_dup: boolean = false;
	public auto_key: number | null = null;
	public cancel_date: string | null = null;
	public cancel_flat_fee: string | null = null;
	public city: string | null = null;
	public color: string | null = null;
	public company: string | null = null;
	public coupons: ReservationCoupon[] = [];
	public customer_id: number | null = null;
	public daily_rate: null | number = null;
	public days_non_receivable: number = 0;
	public discount_rate: null | number = null;
	public discount: null | number = null;
	public due_at_lot: number = 0;
	public editable: boolean;
	public email_requested: StringBool = StringBool.true;
	public email: string | null = null;
	public end_date: string;
	public first_name: string | null = null;
	public fpp_days_redeemed: number = 0;
	public fpp_redeemed: number = 0;
	public hash: string | null = null;
	public hourly_rate: number = 0;
	public last_name: string | null = null;
	public license: string | null = null;
	public location: number;
	public location_code: number | string;
	public make: string | null = null;
	public model: string | null = null;
	public newsletter_opt_in: boolean = false;
	public no_mand_opts: StringBool = StringBool.false;
	public non_receivable: number = 0;
	public notes: string | null = null;
	public number_of_passengers: number | null = null;
	public opt_in: StringBool = StringBool.true;
	public uid: string;
	public parking_zone: number | null = null;
	public pay_on_exit: number = 0;
	public payments: Payment[];
	public phone: string | null;
	public phone2: string | null;
	public phone3: string | null;
	public preload: boolean = false;
	public prepaid_ar: number = 0;
	public prepaid_nr_gross: number = 0;
	public prepaid: number = 0;
	public promo_code: string | null = null;
	public quote: Quote;
	public rate_history_id: null | number = null;
	public rate_name: string | null = null;
	public rate_option: number = 0;
	public rate: null | number = null;
	public redemption_date: string | null = null;
	public reservation_date: string | null = null;
	public reservation: string | null = null;
	public search_source: string | null = null;
	public services: Service[];
	public source: string = 'website';
	public source_id: number = 0;
	public start_date: string;
	public state: string | null = null;
	public state_vehicle: string | null = null;
	public status: Status = Status.active;
	public ticket: number | null = null;
	public text_opt_in: boolean | StringBool = StringBool.true;
	public zip: string | null = null;
	public reward_card: string | null = null;
	public reward_cards_id: number | null = null;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		if (data.customer) {
			this.customer = new Customer(data.customer);
		}

		this.editable = data.editable !== false;
		this.phone = formatPhone(data.phone);
		this.phone2 = formatPhone(data.phone2);
		this.phone3 = formatPhone(data.phone3);
		this.end_date = format(moment(data.end_date || 'now').toDate(), 'YYYY-MM-DD HH:mm:ss');
		this.start_date = format(moment(data.start_date || 'now').toDate(), 'YYYY-MM-DD HH:mm:ss');
		this.notes = stringOrBlank(data.notes);

		this.location = +data.location || 1;
		this.location_code = data.location_code || 0;
		this.uid = stringOrBlank(data.uid);
		this.payments = mapModels(Payment, data.payments) as Payment[];
		this.quote = new Quote(data.quote || {});
		this.services = mapModels(Service, data.services) as Service[];
	}

	public get customer(): Customer {
		const customer = {
			address1: this.address,
			address2: this.address2,
			cell_phone: this.phone3,
			customer: this.customer_id,
			city: this.city,
			email: this.email,
			first_name: this.first_name,
			last_name: this.last_name,
			state: this.state,
			work_phone: this.phone2,
			zip: this.zip,
			number_of_passengers: this.number_of_passengers,
			newsletter_opt_in: this.newsletter_opt_in,
			text_opt_in: this.text_opt_in,
		};

		return new Customer(customer);
	}

	public set customer(data: Customer) {
		const customer = new Customer(data);

		this.address = customer.address1;
		this.address2 = customer.address2;
		this.city = customer.city;
		this.customer_id = +customer.customer;
		this.email = customer.email;
		this.first_name = customer.first_name;
		this.last_name = customer.last_name;
		this.phone = formatPhone(customer.cell_phone);
		this.phone2 = formatPhone(customer.work_phone);
		this.phone3 = formatPhone(customer.cell_phone);
		this.state = customer.state;
		this.zip = customer.zip;
		this.number_of_passengers = customer.number_of_passengers;
		this.newsletter_opt_in = customer.newsletter_opt_in;
		this.text_opt_in = customer.text_opt_in;

		if (customer.additionalFields) {
			this.notes = join(
				'\n',
				zipWith(this.additionalFieldsValue, Object.keys(customer.additionalFields), Object.values(customer.additionalFields)),
			);
		}
	}

	public get isNew() {
		return this.auto_key === null;
	}

	public get quoteRequest(): any {
		const data = {
			customer_id: this.customer_id,
			end_date: this.end_date,
			fpp_redeemed: this.fpp_redeemed,
			promo_code: this.promo_code,
			quote: this.quote.index >= -1 ? this.quote.index : -2,
			services: this.services,
			source: this.source,
			source_id: this.source_id,
			start_date: this.start_date,
		} as any;

		if (this.auto_key) {
			data.auto_key = this.auto_key;
		}

		return data;
	}

	public get vehicle(): Vehicle {
		return new Vehicle({
			color: get('color', this),
			license: get('license', this),
			make: get('make', this),
			model: get('model', this),
			state: get('state_vehicle', this),
		});
	}

	public set vehicle(vehicle: Vehicle) {
		this.color = get('color', vehicle);
		this.license = get('license', vehicle);
		this.make = get('make', vehicle);
		this.model = get('model', vehicle);
		this.state_vehicle = get('state', vehicle);
	}

	public setQuote(quote: Quote) {
		this.quote = new Quote(quote);
	}

	public setPayment(data: Payment) {
		const payment = new Payment(data);
		const name = `${getOrString('first_name', this)} ${getOrString('last_name', this)}`;
		payment.amount = getOrZero('quote.prepay_amount', this);
		payment.customer_id = getOrZero('customer_id', this);
		payment.notes = `Charge for reservation. Booked by: ${name}`;
		this.payments.push(payment);
	}

	public setServices(services: Service[]) {
		this.services = mapModels(Service, services) as Service[];
	}

	protected additionalFieldsValue = (key: string, value: string) => `${key}: ${value}`;
}
