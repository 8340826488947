import { get, has } from '@/utilities/helpers';

export default class Model {
	public constructor(data: any = {}) {
		this.initialize(data);
	}

	public initialize(data: any = {}) {
		Object.keys(data).forEach((key: string) => {
			if (has(key)(this)) {
				(this as any)[key] = get(key, data);
			}
		});
	}
}
