export default {
	visa: {
		image: 'visa.svg',
	},
	'american-express': {
		image: 'amex.svg',
	},
	'master-card': {
		image: 'mastercard.svg',
	},
	discover: {
		image: 'discover.svg',
	},
	'diners-club': {
		image: 'diners.svg',
	},
	jcb: {
		image: 'jcb.svg',
	},
	ach: {
		image: 'ach.svg',
	},
	VI: {
		image: 'visa.svg',
	},
	AX: {
		image: 'amex.svg',
	},
	MC: {
		image: 'mastercard.svg',
	},
	DI: {
		image: 'discover.svg',
	},
	DC: {
		image: 'diners.svg',
	},
	JCB: {
		image: 'jcb.svg',
	},
	ACH: {
		image: 'ach.svg',
	},
};

export const types: { [card: string]: string } = {
	VI: 'visa',
	AX: 'american-express',
	MC: 'mastercard',
	DI: 'discover',
	DC: 'diners-club',
	JC: 'jcb',
	ACH: 'ach',
};
