import Service from '@/services/Service';

import Account from '@/models/Account';
import Model from '@/models/Model';

import ACHAccount from '@/models/ACHAccount';
import CreditCard from '@/models/CreditCard';
import ParkingZone from '@/models/ParkingZone';
import Recurring from '@/models/Recurring';
import { mapModels } from '@/utilities/helpers';
import { FormatAddress } from '@/utilities/formatters';

export default class Group extends Model implements Account {
	public active: boolean = true;
	public ach_accounts: ACHAccount[] = [];
	public ach_account_number: string | null = null;
	public ach_customer_token: string | null = null;
	public ach_payment_token: string | null = null;
	public additionalFields: { [prop: string]: string } = {};
	public address1: string | null = null;
	public address2: string | null = null;
	public attention: string | null = null;
	public balances: Array<{ location: string, id: number, balance: number }> = [];
	public code: string = '';
	public code_search: boolean = true;
	public company: number | null = null;
	public company_name: string = '';
	public cc_address: string | null = null;
	public cc_expiration: string | null = null;
	public cc_name: string | null = null;
	public cc_number: string | null = null;
	public cc_token: string | null = null;
	public cc_type: string | null = null;
	public cc_zip: string | null = null;
	public city: string | null = null;
	public credit_cards: CreditCard[] = [];
	public discount: number | null = null;
	public email: string | null = null;
	public email_receipt: boolean = false;
	public fax: string | null = null;
	public mand_option: number | null = null;
	public newsletter_opt_in: boolean = false;
	public notes: string | null = null;
	public parking_zones: ParkingZone[] = [];
	public pin: string | null = null;
	public phone: string | null = null;
	public rate: number | null = null;
	public recurring: Recurring[] = [];
	public state: string | null = null;
	public tax_exempt: boolean | string = false;
	public timestamp: string | Date = new Date();
	public uid: string | null = null;
	public validations_token: string | null = null;
	public zip: string | null = null;

	// Search result properties
	public search_description: string | null = null;
	public type: string | null = null;	// can be customer or ticket

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		this.ach_accounts = mapModels(ACHAccount, data.ach_accounts) as ACHAccount[];
		this.credit_cards = mapModels(CreditCard, data.credit_cards) as CreditCard[];
		this.recurring = mapModels(Recurring, data.recurring) as Recurring[];
	}

	public get account_id(): number {
		return this.company || 0;
	}

	public get account_other_id(): string {
		return '';
	}

	public get account_type(): string {
		return 'group';
	}

	public get address(): string {
		return FormatAddress({
			address1: this.address1 || undefined,
			address2: this.address2 || undefined,
			city: this.city || undefined,
			state: this.state || undefined,
			zip: this.zip || undefined,
		});
	}

	public get attn(): string {
		return this.attention || '';
	}

	public get balance(): number {
		return this.balances.reduce((balance, item) => {
			balance += item.balance;
			return balance;
		}, 0);
	}

	public get has_vehicles(): boolean {
		return false;
	}

	public get name(): string {
		return this.company_name;
	}

	public get primaryCard(): CreditCard | null {
		return this.credit_cards.find((card) => card && card.primary) || null;
	}

	public get secondaryCard(): CreditCard | null {
		return this.credit_cards.find((card) => card && !card.primary) || null;
	}

	public get primaryACH(): ACHAccount | null {
		return this.ach_accounts.find((account) => account && account.primary) || null;
	}

	public get secondaryACH(): ACHAccount | null {
		return this.ach_accounts.find((account) => account && !account.primary) || null;
	}

	public get work_phone(): string | null {
		return null;
	}

	public set work_phone(value: string | null) {
		// nothing to set to for groups
	}

	public getAccounts(params: any) {
		return Service.post('getAccounts', { params });
	}

	public getBalance(location: number | null): number {
		if (!location) {
			return this.balance;
		}

		const balance = this.balances.find((item) => +item.id === location);
		return balance ? balance.balance : 0;
	}

	public getInvoices(params: any, subLocation: any = null) {
		if (!params) {
			params = { filters: [] };
		}

		params.filters.push({ name: 'Group View', value: this.account_id });
		return Service.post('getInvoices', { params, subLocation });
	}

	public payBalance(amount: number, payment_type: string, subLocation: any = null) {
		return Service.post('payGroupBalance', { amount, payment_type, subLocation });
	}

	public async saveACHAccount() {
		const ach = this.primaryACH;
		return Service.post('updateGroupACH', { ach });
	}

	public async saveCreditCard(type: string = 'primary') {
		const card = this.primaryCard;
		return Service.post('updateGroupCard', { card });
	}

	public setACHAccount(ach: ACHAccount) {
		this.ach_accounts[0] = ach;
	}

	public setCreditCard(type: string, card: CreditCard) {
		type = type || 'primary';
		const index = this.credit_cards.findIndex((c) => c && ((c.primary && type === 'primary') || (!c.primary && type !== 'primary')));

		if (index >= 0) {
			this.credit_cards[index] = card;
		} else {
			card.primary = (type === 'primary');
			this.credit_cards.push(card);
		}
	}

	public async updateRecurringMethod(paymentMethod: string, subLocation: number) {
		return Service.post('updateGroupRecurringMethod', { paymentMethod, subLocation });
	}
}
