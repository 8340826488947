var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectableLocations.length > 1)?_c('AppInput',_vm._b({staticClass:"np-location-selector",style:({ minWidth: _vm.minWidth }),attrs:{"name":"location-selector"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var update = ref.update;
var value = ref.value;
return [(_vm.selectableLocations.length <= _vm.locationLimit)?[_c('NpMultiselect',{staticClass:"np-location-selector__multiselect",attrs:{"id":_vm.$attrs.id,"allow-empty":false,"custom-label":function (ref) {
	var details = ref.details;

	return details ? details.name : null;
},"disabled":_vm.locked,"max-height":480,"options":_vm.selectableLocations,"placeholder":"Select a Location...","show-labels":false,"track-by":"codeID","value":value},on:{"input":function($event){return update($event)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',[_c('h1',{staticClass:"np-font-semibold np-text-sm md:np-text-base np-tracking-wide np-uppercase"},[_vm._v(_vm._s(props.option.details.name))]),_c('address',{staticClass:"np-mb-0 np-not-italic np-text-gray-600 np-text-xs"},[_vm._v(_vm._s(_vm.locationAddress(props.option.details)))])])]}}],null,true)})]:_vm._e()]}}],null,false,760357647),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'AppInput',_vm.$attrs,false)):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }