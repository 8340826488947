
import Multiselect from 'vue-multiselect';
import { get } from '@/utilities/helpers';

export default {
	functional: true,
	components: { Multiselect },
	render(h: any, context: any) {
		const data: any = get('data', context);

		if (!data.scopedSlots) {
			data.scopedSlots = {};
		}

		data.scopedSlots.caret = (props: any) => h(
			'div',
			{
				class: 'multiselect__select',
				on: {
					mousedown(event: MouseEvent) {
						event.preventDefault();
						event.stopPropagation();
						props.toggle();
					},
				},
			},
			[
				h('FaIcon', {
					class: 'np-multiselect__icon',
					props: {
						icon: ['fal', 'angle-down'],
						size: 'lg',
					},
				}),
			],
		);

		return h(Multiselect, {
			class: 'np-multiselect',
			on: data.on,
			model: data.model,
			props: {
				selectLabel: 'Click to select',
				deselectLabel: 'Click to remove',
				closeOnSelect: !get('attrs.multiple', data),
				...data.attrs,
			},
			scopedSlots: data.scopedSlots,
		});
	},
};
