import { parse } from 'date-fns';
import {
	ActionTree,
	GetterTree,
	Module,
} from 'vuex';
import Quote from '@/models/Quote';
import Reservation from '@/models/Reservation';
import Service from '@/models/Service';
import { getOrArray, getOrNull, getOrString } from '@/utilities/helpers';

import ReservationStep from '@/types/reservationStep';
import Analytics from '../utilities/analytics';

interface State {
	[propName: string]: any;
}

const analytics = new Analytics();

const Getters: GetterTree<State, StoreTypes.RootState> = {
	customer(state, getters, rootState, rootGetters): boolean {
		return rootGetters.isLoggedIn;
	},

	location(state, getters, rootState, rootGetters): string {
		return rootGetters['location/code'];
	},

	products(state, getters, rootState, rootGetters) {
		const products = [];
		const reservation = getOrNull('reservation.reservation', rootState);

		if (!reservation || !reservation.quote.details.rate) {
			return [];
		}

		products.push({
			id: `rate_${reservation.quote.details.rate}`,
			name: `rate_${reservation.quote.name}`,
			price: reservation.quote.parking - +reservation.quote.promo,
			category: 'Parking',
		});

		const services = reservation.services.map((service: Service) => ({
			id: `service_${service.service}`,
			name: service.description,
			price: service.price,
			category: 'Services',
		}));

		if (services.length) {
			products.push(...services);
		}

		return products;
	},

	reservation(state, getters, rootState): Reservation {
		return rootState.reservation.reservation;
	},

	source(state, getters, rootState, rootGetters): string {
		return rootGetters['location/source'];
	},
};

const Actions: ActionTree<State, StoreTypes.RootState> = {
	action(context, action: Analytics.ActionParams) {
		analytics.action(
			action.type,
			action.label,
			action.value,
			action.step || 0,
			action.goalValue,
		);
	},

	bookReservation({ getters, rootGetters }, reservation: Reservation) {
		const quote = reservation.quote;
		const details = quote.details;
		const products = [{
			id: `rate_${details.rate}`,
			name: reservation.rate_name,
			price: quote.parking_net,
			category: 'Parking',
		}];
		const settings = rootGetters['location/settings'];

		reservation.services.forEach((service: Service) => {
			products.push({
				id: `service_${service.service}`,
				name: service.ws_description || service.description,
				price: service.price,
				category: 'Services',
			});
		});

		analytics.purchase(
			products,
			reservation,
			getters.location,
		);

		if (settings.google_ppc_code && settings.google_ppc_label) {
			analytics.googleConversion(
				settings.google_ppc_code,
				settings.google_ppc_label,
				reservation.reservation as string,
				quote.grand_total,
				quote.parking_net,
				quote.taxes_subtotal,
			);
		}

		if (settings.expedia_code) {
			analytics.expediaConversion(
				settings.expedia_code,
				reservation.reservation as string,
				quote.grand_total,
			);
		}
	},

	registerCustomer({ rootState }) {
		const registrationType = rootState.settings.registrationType || {};
		analytics.googleConversion(registrationType.conversion_id, registrationType.conversion_label);
		analytics.actionFacebookPixel('CompleteRegistration');
	},

	addService({ getters, rootState }, service: Service) {
		const description = service.ws_description || service.description;
		analytics.add(
			`service_${service.service}`,
			description,
			service.price,
			0.0,
			'',
			getOrArray('reservation/services', getters).length,
			'Services',
			ReservationStep.Services,
			getters.customer,
			getters.location,
			getters.source,
		);

		analytics.clickProduct(
			`service_${service.service}`,
			service.description,
			'Services',
		);

		analytics.detail(
			`service_${service.service}`,
			service.description,
			'Services',
			getters.location,
			getters.source,
		);
	},

	displayQuotes({ getters }, quotes: any[]) {
		quotes.forEach((quote, index) => {
			analytics.impression(
				`rate_${quote.details.rate}`,
				quote.name,
				index + 1,
				'Quotes',
				getters.location,
				getters.source,
			);
		});
	},

	displayServices({ getters }, services: Service[]) {
		services.forEach((service, index) => {
			const description = service.ws_description || service.description;
			analytics.impression(
				`service_${service.service}`,
				description,
				index + 1,
				'Services',
				getters.location,
				getters.source,
			);
		});
	},

	removeService({ getters }, service: Service) {
		analytics.remove(
			`service_${service.service}`,
			getters.location,
			getters.source,
		);
	},

	reservationStep({ getters, rootGetters, rootState }, step: ReservationStep) {
		if (step <= ReservationStep.Dates) {
			return;
		}

		analytics.action(
			'UX',
			'click',
			`Reservation - ${ReservationStep[step]}`,
			step,
		);

		if (step < ReservationStep.Confirmation) {
			analytics.checkout(
				getters.products,
				step,
				0,
				0,
				getters.location,
				getters.source,
			);
		}

		if (step === ReservationStep.Quotes) {
			analytics.quote(
				parse(getOrString('reservation.dates.checkIn', rootState)),
				parse(getOrString('reservation.dates.checkout', rootState)),
				getters.location,
			);
		}

		if (step === ReservationStep.Confirmation) {
			const { quote } = getters.reservation;
			const net = +quote.parking + +quote.services - +quote.promo;
			const settings = rootGetters['location/settings'];

			// analytics.purchase(
			// 	getters.products,
			// 	getters.reservation,
			// 	getters.location,
			// );

			if (settings.google_ppc_code && settings.google_ppc_label) {
				analytics.googleConversion(
					settings.google_ppc_code,
					settings.google_ppc_label,
					getters.reservation.reservation,
					quote.total,
					net,
				);
			}

			if (settings.google_survey_merch_id) {
				analytics.googleSurveyOptIn(
					settings.google_survey_merch_id,
					getters.reservation,
				);
			}

			if (settings.expedia_code) {
				analytics.expediaConversion(
					settings.expedia_code,
					getters.reservation.reservation,
					quote.total,
				);
			}
		}
	},

	setQuote({ getters }, quote: Quote) {
		analytics.add(
			`rate_${quote.details.rate}`,
			quote.name,
			quote.parking_net,
			quote.taxes_subtotal,
			quote.promo_code,
			quote.index + 1,
			'Quotes',
			ReservationStep.Quotes,
			getters.customer,
			getters.location,
			getters.source,
		);

		analytics.clickProduct(
			`rate_${quote.details.rate}`,
			quote.name,
			'Quotes',
		);

		analytics.detail(
			`rate_${quote.details.rate}`,
			quote.name,
			'Quotes',
			getters.location,
			getters.source,
		);
	},
};

export default {
	namespaced: true,
	getters: Getters,
	actions: Actions,
} as Module<State, StoreTypes.RootState>;
