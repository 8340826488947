import Customer from '@/models/Customer';
import Quote from '@/models/Quote';
import Reservation from '@/models/Reservation';
import Service from '@/models/Service';
import Vehicle from '@/models/Vehicle';
import BaseService from '@/services/Service';
import { getOrNull } from '@/utilities/helpers';

export default new class ReservationService extends BaseService {
	public cancelReservation(reservation: string, location: string) {
		const data = {
			location,
			reservation,
		};

		return BaseService.post('cancelReservation', data);
	}

	public emailReservation(location: string, id: string) {
		const data = {
			location,
			id,
		};

		return BaseService.post('emailReservation', data);
	}

	public list(): Promise<Reservation[]> {
		return BaseService.post('listReservations');
	}

	public getServices(
		location: string,
		start: string,
		end: string,
		vehicle: Vehicle = new Vehicle(),
		reservationId: null | number = null,
	): Promise<Service[]> {
		return BaseService.post('getServices', {
			location,
			start,
			end,
			vehicle_type: getOrNull('type', vehicle),
			reservation_id: reservationId,
		});
	}

	public getQuotes(reservation: Reservation, location: Locations.Location, resRate: boolean = false, multi_locations: Locations.Location[]): Promise<Quote[]> {
		return BaseService.post('getQuotes', {
			location: location.codeID,
			multi_locations,
			reservation: reservation.quoteRequest,
			resRate,
		});
	}

	public saveReservation(reservation: Reservation, location: Locations.Location) {
		return BaseService.post('saveReservation', {
			reservation,
			location: location.codeID,
		});
	}


	public validateCustomer(customer: Customer, vehicle: Vehicle, location: string) {
		return BaseService.post('validateCustomer', { customer, vehicle, location });
	}

	public sendLookupEmail(email: string) {
		return BaseService.post('sendLookupEmail', { email });
	}
}();
