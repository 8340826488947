import axios, { AxiosError, AxiosResponse } from 'axios';
import { get, getOrArray, has } from '@/utilities/helpers';

export default class Service {
	public static ajaxUrl: string = NP_PLUGIN_DATA.ajaxUrl;

	public static async sendPost(data: any) {
		try {
			const response = await axios.post(this.ajaxUrl, {
				action: 'np_ajax',
				...data,
			});

			if (getOrArray('data.errors', response).length) {
				throw response.data.errors;
			}

			return response.data;
		} catch (errors) {
			if (has('response.data', errors)) {
				throw errors.response.data;
			}

			throw errors;
		}
	}

	public static get(method: string, data: any = {}): Promise<AxiosResponse> {
		return axios.get(this.ajaxUrl, {
			params: {
				method,
				...data,
			},
		});
	}

	public static post(method: string, data: any = {}): Promise<any> {
		return axios.post(this.ajaxUrl, {
			method,
			...data,
		})
			.then((response: AxiosResponse) => Promise.resolve(get('data.data', response)))
			.catch((reject: AxiosError) => {
				if (has('response.data', reject) && reject.response) {
					return Promise.reject(reject.response.data);
				}

				return Promise.reject(reject);
			});
	}
}

export interface Response {
	data?: any;
	errors?: any;
}
