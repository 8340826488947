class NavMenu {
	public dropdowns: HTMLElement[];
	public element: HTMLElement;
	public menu: HTMLElement;
	public toggle: HTMLElement;

	public constructor(element: HTMLElement) {
		this.element = element;
		this.toggle = this.element.querySelector('.np-hamburger') as HTMLElement;
		this.menu = this.element.querySelector('.np-nav-menu__list') as HTMLElement;
		this.dropdowns = [].slice.call(document.querySelectorAll('.menu-item-has-children'));
	}

	public initialize() {
		this.addEvents();
	}

	protected addEvents() {
		this.dropdowns.forEach((el: HTMLElement) => {
			el.addEventListener('click', (event) => {
				this.toggleSubMenu(event, el);
			});
		});

		document.addEventListener('click', this.checkCloseMenu.bind(this));
		this.toggle.addEventListener('click', this.toggleMenu.bind(this));
	}

	protected checkCloseMenu(event: any) {
		if (
			!this.element.classList.contains('np-nav-menu--active')
			|| event.target.classList.contains('np-hamburger')
		) {
			return;
		}

		if (
			!this.isDescendant(this.element, event.target)
			&& event.target !== this.element
		) {
			this.toggleMenu();
		}
	}

	protected deactivateDropdowns() {
		this.dropdowns.forEach((el: HTMLElement) => {
			el.classList.remove('np-sub-menu--active');
		});
	}

	protected isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
		let node = child.parentNode;

		while (node !== null) {
			if (node === parent) {
				return true;
			}

			node = node.parentNode;
		}

		return false;
	}

	protected toggleMenu() {
		if (this.element.classList.contains('np-nav-menu--active')) {
			this.element.classList.remove('np-nav-menu--active');
			this.deactivateDropdowns();
		} else {
			this.element.classList.add('np-nav-menu--active');
		}
	}

	protected toggleSubMenu(event: any, element: HTMLElement) {
		if (!element) {
			return;
		}

		if (element.classList.contains('np-sub-menu--active')) {
			element.classList.remove('np-sub-menu--active');
			return;
		}

		this.deactivateDropdowns();
		element.classList.add('np-sub-menu--active');
	}
}

document.addEventListener('DOMContentLoaded', () => {
	[].slice.call(document.querySelectorAll('.np-nav-menu--has-mobile'))
		.forEach((element) => {
			const menu = new NavMenu(element);
			menu.initialize();
		});
});
