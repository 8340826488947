import Vue from 'vue';

function replacer(phone: string, p1: string, p2: string, p3: string) {
	if (!p1 || !p2 || !p3) {
		return phone;
	}

	return `(${p1}) ${p2}-${p3}`;
}

Vue.filter('phone', (value: string | number): string => String(value).replace(/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/, replacer));
