import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEqual from 'lodash/fp/isEqual';
import join from 'lodash/fp/join';
import has from 'lodash/fp/has';
import map from 'lodash/fp/map';
import merge from 'lodash/fp/merge';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import findKey from 'lodash/fp/findKey';
import padCharsStart from 'lodash/fp/padCharsStart';
import zipWith from 'lodash/fp/zipWith';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import truncate from 'lodash/truncate';

export {
	cloneDeep,
	debounce,
	get,
	getOr,
	find,
	findIndex,
	findKey,
	has,
	isEmpty,
	isEqual,
	isObject,
	isString,
	join,
	merge,
	padCharsStart,
	truncate,
	zipWith,
};

export const checkTrue = (value: boolean | number | string) => [true, 'true', '1', 1].includes(value);

export const formatPhone = (value: null | number | string): null | string => {
	if (!value) {
		return null;
	}

	return String(value).replace(
		/\D*(\d{1})?\D*(\d{3})\D*(\d{3})\D*(\d{4})(\S?)/,
		'($2) $3-$4 $5',
	).trim();
};

/**
 * Returns a function that will keep the current value if it
 * is an array or return an empty array.
 */
export const arrayOrDefault = (defaultValue: any, value: any) => (Array.isArray(value) ? value : defaultValue);
export const arrayOrEmpty = curry(arrayOrDefault)([]);
export const arrayOrNull = curry(arrayOrDefault)(null);

/**
 * Returns a function that will convert the given data into the
 * provided model.
 *
 * @param Model
 * @param value
 */
const toModel = (Model: any, value: any) => new Model(value);

/**
 * Returns a function that will convert given data into an array of the
 * provided model.
 *
 * @param model
 * @param values
 */
export const mapModels = (model: any, values: any[]) => map(curry(toModel)(model), values);

/**
 * Returns a function that will check if the given value is a string, return it,
 * or otherwise the default.
 *
 * @param defaultValue
 * @param value
 */
export const stringOrDefault = (defaultValue: any, value: any) => (isString(value) ? value : defaultValue);

/**
 * Returns a function that will check if the given value is a string, return it,
 * or otherwise return null.
 *
 * @param value
 */
export const stringOrNull = curry(stringOrDefault)(null);
export const stringOrBlank = curry(stringOrDefault)('');

export const getOrArray = curry(getOr)([]);
export const getOrFalse = curry(getOr)(false);
export const getOrNull = curry(getOr)(null);
export const getOrObject = curry(getOr)({});
export const getOrString = curry(getOr)('');
export const getOrTrue = curry(getOr)(true);
export const getOrZero = curry(getOr)(0);

const encodeParam = (paramKey: string, paramValue: null | number | string) => `${encodeURIComponent(paramKey)}=${encodeURIComponent(paramValue ? paramValue.toString() : '')}`;

export const urlParams = (params: { [prop: string]: null | number | string }) => join(
	'&',
	zipWith(encodeParam, Object.keys(params), Object.values(params)),
);

export function parseErrors(data: any) {
	let response;
	const errors: any = {};

	if (data.response) {
		({ response } = data);
	} else {
		response = data;
	}

	if (response.data && Array.isArray(response.data.errors)) {
		response.data.errors.forEach((error: any) => {
			if (error.name) {
				errors[error.name] = error.text;
			} else {
				errors.page = error.text;

				if (!Array.isArray(errors.list)) {
					errors.list = [];
				}

				error.list.push(error.text);
			}
		});
	}

	return errors;
}
