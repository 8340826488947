import { differenceInDays, format, parse } from 'date-fns';
import Reservation from '@/models/Reservation';

declare const ANALYTICS_SETTINGS: { [propName: string]: any };
declare const hsq: any;
declare const analytics_include_tax: any;
declare const dotq: any;
declare const fbq: any;
declare const ga: any;
declare const ga4: any;
declare const gapi: any;
declare const gtag: any;
declare const gtm: any;
declare const goptimize: any;
declare const google_trackConversion: any;
declare const shopper: any;
declare const site_catalyst: any;
declare const uetq: any;

export default class Analytics {
	protected loadedEnhancedEcommerce = false;

	// public trackPage(name: string) {
	// 	if (this.hasGoogleAnalytics()) {
	// 		ga('send', 'pageview', name);
	// 	}
	// }

	public action(
		type: string,
		label: string,
		value: string,
		step: number,
		goalValue?: string,
	) {
		this.actionGoogleAnalytics(type, label, value, step);
		this.actionBingUniversalEventTracker(label, value, goalValue);
		this.actionFacebookPixel(label, value);
		this.actionYahooGemini(type, label, value, goalValue);
		this.actionHubspot(label, value);
	}

	/**
	 *  Adds an impression to analytics (viewing individual quotes, etc)
	 *
	 * @param id
	 * @param name
	 * @param position
	 * @param list
	 * @param location
	 * @param source
	 */
	public impression(
		id: string,
		name: string,
		position: number,
		list: number | string,
		location: string,
		source: string,
	) {
		if (this.hasGoogleAnalytics()) {
			if (typeof list !== 'undefined') {
				ga('ec:addImpression', {
					id,
					name,
					position,
					list,
					brand: location,
					variant: source,
				});

				ga('ec:setAction', 'click', { list });
			}

			this.actionGoogleAnalytics('UX', 'impression', name);
		}

		this.actionBingUniversalEventTracker('Impression', name, position);
		this.actionFacebookPixel('ViewContent', {
			content_category: 'Impression',
			content_type: 'product',
			content_name: name,
			content_ids: [id],
		});
		this.actionYahooGemini('Impression', name, position);
		this.actionHubspot('Impression');
	}

	public detail(
		id: string,
		name: string,
		list: number | string,
		location: string,
		source: string,
	) {
		if (this.hasGoogleAnalytics()) {
			ga('ec:addProduct', {
				id,
				name,
				list,
				brand: location,
				variant: source,
			});

			ga('ec:setAction', 'detail', { list });
			this.actionGoogleAnalytics('UX', 'detail', name);
			this.actionFacebookPixel('ViewContent', {
				content_category: 'Detail',
				content_type: 'product',
				content_name: name,
				content_ids: [id],
			});
		}
	}

	public add(
		id: string,
		name: string,
		price: number,
		tax: number,
		coupon: number | string,
		position: number,
		list: string,
		step: number | string,
		isCustomer: boolean | number,
		location: string,
		source: string,
	) {
		if (this.hasGoogleAnalytics()) {
			ga('ec:addProduct', {
				id,
				name,
				category: list,
				brand: location,
				variant: source,
				position,
				list,
				coupon,
				step,
				Customer: isCustomer,
			});

			ga('ec:setAction', 'add', {
				id,
				revenue: price,
				tax,
				coupon,
				position,
				category: list,
				list,
				step,
				Customer: isCustomer,
				brand: location,
				variant: source,
			});

			this.actionGoogleAnalytics('UX', 'add', name);
		}

		this.actionBingUniversalEventTracker('AddProduct', name, price);
		this.actionFacebookPixel('AddToCart', {
			content_type: 'product',
			content_name: name,
			content_ids: [id],
			value: price,
			currency: 'USD',
		});
		this.actionYahooGemini('AddProduct', name, price);
	}

	public remove(id: string, location: string, source: string) {
		if (this.hasGoogleAnalytics()) {
			ga('ec:setAction', 'remove', {
				id,
				brand: location,
				variant: source,
			});
			this.actionGoogleAnalytics('UX', 'click', 'remove product');
		}
	}

	public click(name: string) {
		if (this.hasGoogleAnalytics()) {
			ga('ec:setAction', 'click');
			this.actionGoogleAnalytics('UX', 'click', name);
		}

		this.actionBingUniversalEventTracker('Click', name);
		this.actionFacebookPixel('ViewContent', {
			content_category: 'Content',
			content_type: 'product',
			content_name: name,
		});
		this.actionYahooGemini('Click', name);
		this.actionHubspot('Click');
	}

	public clickProduct(id: string, name: string, list: string) {
		if (this.hasGoogleAnalytics()) {
			ga('ec:addProduct', {
				id,
				name,
			});

			ga('ec:setAction', 'click', { list });
			this.actionGoogleAnalytics('UX', 'view', name);
		}

		this.actionFacebookPixel('ViewContent', {
			content_category: 'Product Click',
			content_type: 'product',
			content_name: name,
			content_ids: [id],
		});
	}

	public quote(startDate: Date, endDate: Date, location: string) {
		try {
			if (this.hasGoogleAnalytics()) {
				ga('ec:setAction', 'click');

				const details: any = {};

				if (ANALYTICS_SETTINGS.details.searched_checkin) {
					details[ANALYTICS_SETTINGS.details.searched_checkin] = format(
						startDate,
						'MM/DD/YYYY',
					);
				}

				if (ANALYTICS_SETTINGS.details.searched_checkout) {
					details[ANALYTICS_SETTINGS.details.searched_checkout] = format(
						endDate,
						'MM/DD/YYYY',
					);
				}

				if (ANALYTICS_SETTINGS.details.searched_stay_length) {
					details[
						ANALYTICS_SETTINGS.details.searched_stay_length
					] = differenceInDays(endDate, startDate).toString();
				}

				if (ANALYTICS_SETTINGS.details.searched_out) {
					details[ANALYTICS_SETTINGS.details.searched_out] = differenceInDays(
						startDate,
						new Date(),
					).toString();
				}

				this.actionGoogleAnalytics('Quotes', 'Updated', details);
			}

			this.actionBingUniversalEventTracker('Quotes', startDate);
			this.actionFacebookPixel('Search');
			this.actionYahooGemini('Quotes', format(startDate, 'MM/DD/YYYY'));
			this.actionHubspot('Quotes');

			if (this.hasSiteCatalyst()) {
				site_catalyst.pageName = `${ANALYTICS_SETTINGS.pagename}:reservation:quote`;
				site_catalyst.pageType = 'info';
				site_catalyst.events = 'event1';
				site_catalyst.prop1 = `${ANALYTICS_SETTINGS.pagename}:reservation`;
				site_catalyst.products = ';parkingType1;';
				site_catalyst.eVar1 = format(startDate, 'MM/DD/YYYY');
				site_catalyst.eVar2 = format(endDate, 'MM/DD/YYYY');
				site_catalyst.eVar3 = location;
				site_catalyst.t();
			}
		} catch (ex) {
			// TODO: Analytics AJAX
			// jQuery.ajax({
			// 	type: 'POST',
			// 	url: Globals.ajaxurl,
			// 	data:
			// 		'action=np_ajax&method=timeout&ajax_method=AnalyticsPurchase',
			// });
		}

		if (this.hasSojern()) {
			const params = {
				rd1: format(startDate, 'YYYY-MM-DD'),
				rd2: format(endDate, 'YYYY-MM-DD'),
				// rc1: city,
				// rc2: city,
				ra1: location,
			};

			const sojern = ANALYTICS_SETTINGS.details.sojern;
			const sojern_search = ANALYTICS_SETTINGS.details.sojern_search;

			/* Please do not modify the below code. */
			const paramsArr = [];
			const pl = document.createElement('script');
			const defaultParams = { vid: 'car', et: 'rs' };
			for (const key in defaultParams) {
				(params as any)[key] = (defaultParams as any)[key];
			}
			for (const key in params) {
				const value = (params as any)[key];
				paramsArr.push(`${key}=${encodeURIComponent(value)}`);
			}
			pl.type = 'text/javascript';
			pl.async = true;
			pl.src = `https://beacon.sojern.com/pixel/p/${sojern_search || sojern}?f_v=v6_js&p_v=2&${paramsArr.join('&')}`;

			(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
		}
	}

	public purchase(
		products: any[],
		reservation: Reservation,
		location: string,
	) {
		try {
			const quote = reservation.quote;
			const details: any = { value: reservation.reservation };
			const startDate = parse(reservation.start_date);
			const endDate = parse(reservation.end_date);
			const revenue = +quote.parking_subtotal + +quote.services_subtotal - +quote.savings;
			const tax = +quote.options_subtotal + +quote.taxes_subtotal;
			const promo = reservation.promo_code;
			let total = revenue;

			if (this.hasGoogleAnalytics()) {
				details.transaction_id = reservation.reservation
				details.value = total
				details.tax = tax
				details.currency = 'USD'
				products.forEach((item: any) => {
					details.items = [{
						item_id: item.id,
						item_name: item.name,
						price: item.price,
						item_category: item.category,
						coupon: promo,
						quantity: 1,
						item_brand: location,
						item_variant: reservation.source,
					}]
				});

				if (
					typeof analytics_include_tax !== 'undefined'
					&& analytics_include_tax === true
				) {
					total += tax;
				}

				ga4('ec:setAction', 'purchase', {
					id: reservation.reservation,
					revenue,
					tax,
					promo,
				});

				if (ANALYTICS_SETTINGS.details.booked_checkin) {
					details[ANALYTICS_SETTINGS.details.booked_checkin] = format(
						startDate,
						'MM/DD/YYYY',
					);
				}

				if (ANALYTICS_SETTINGS.details.booked_checkout) {
					details[ANALYTICS_SETTINGS.details.booked_checkout] = format(
						endDate,
						'MM/DD/YYYY',
					);
				}

				if (ANALYTICS_SETTINGS.details.booked_stay_length) {
					details[
						ANALYTICS_SETTINGS.details.booked_stay_length
					] = differenceInDays(endDate, startDate).toString();
				}

				if (ANALYTICS_SETTINGS.details.booked_out) {
					details[ANALYTICS_SETTINGS.details.booked_out] = differenceInDays(
						startDate,
						new Date(),
					).toString();
				}

				this.actionGoogleAnalytics('UX', 'purchase', details);
			}

			this.actionBingUniversalEventTracker(
				'Purchase',
				reservation.reservation,
				total,
			);
			this.actionYahooGemini(
				'Purchase',
				reservation.reservation,
				total,
				total,
			);
			this.actionHubspot('Purchase', total);

			if (this.hasFacebookPixel()) {
				try {
					this.actionFacebookPixel('Purchase', {
						value: total,
						currency: 'USD',
					});
				} catch (error) {
					/* Failed */
				}
			}

			if (this.hasSiteCatalyst()) {
				site_catalyst.pageName = `${ANALYTICS_SETTINGS.pagename}:reservation:confirmation`;
				site_catalyst.pageType = 'info';
				site_catalyst.events = 'purchase';
				site_catalyst.prop1 = `${ANALYTICS_SETTINGS.pagename}:reservation`;
				site_catalyst.products = `;parkingType1;${differenceInDays(endDate, startDate).toString()};${total}`;
				site_catalyst.purchaseID = `${location}_${reservation.reservation}`;
				site_catalyst.eVar1 = format(startDate, 'MM/DD/YYYY');
				site_catalyst.eVar2 = format(endDate, 'MM/DD/YYYY');
				site_catalyst.eVar3 = location;
				site_catalyst.t();
			}

			if (this.hasSojern()) {
				(() => {
					const vehicle = reservation.vehicle;
					const params: any = {
						rd1: format(startDate, 'MM/DD/YYYY HH:mm'),
						rd2: format(endDate, 'MM/DD/YYYY HH:mm'),
						ra1: location,
						rp: total,
						rconfno: reservation.reservation,
						hd1: format(startDate, 'YYYY-MM-DD'),
						hd2: format(endDate, 'YYYY-MM-DD'),
						hc1: reservation.city,
						hs2: reservation.state,
						hl: reservation.customer_id,
						pc: `${vehicle.make} ${vehicle.model}`.trim(),
						hpid: location,
						hdc: reservation.promo_code,
						hp: total,
						hcu: 'USD',
						hconfno: reservation.reservation,
					};

					/* Please do not modify the below code. */
					const cid: any = [];
					const paramsArr: any = [];
					const cidParams: any = [];
					const pl = document.createElement('script');
					const defaultParams: any = { vid: 'car' };

					Object.keys(defaultParams).forEach((key: string) => {
						params[key] = defaultParams[key];
					});

					Object.keys(cidParams).forEach((key: string) => {
						cid.push(params[cidParams[key]]);
					});

					params.cid = cid.join('|');

					const sojern = ANALYTICS_SETTINGS.details.sojern;
					const sojern_conversion = ANALYTICS_SETTINGS.details.sojern_conversion;

					Object.keys(params).forEach((key) => {
						paramsArr.push(`${key}=${encodeURIComponent(params[key])}`);
					});
					pl.type = 'text/javascript';
					pl.async = true;
					pl.src = `https://beacon.sojern.com/pixel/p/${sojern_conversion || sojern}?f_v=v6_js&p_v=1&${paramsArr.join('&')}`;

					(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
				})();
			}

			if (this.hasShopperApproved()) {
				const sendDays = differenceInDays(endDate, new Date()) + 3;
				const saValues = {
					site: shopper,
					orderid: reservation.reservation,
					name: `${reservation.first_name} ${reservation.last_name}`,
					email: reservation.email,
					days: sendDays,
				};

				const saLoadScript = (src: string) => {
					const js = window.document.createElement('script');
					js.src = src;
					js.type = 'text/javascript';
					document.getElementsByTagName('head')[0].appendChild(js);
				};

				const date = new Date();
				if (date.getTime() - 172800000 > 1444407397000) {
					saLoadScript(
						`//www.shopperapproved.com/thankyou/rate/${
							shopper
						}.js`,
					);
				} else {
					saLoadScript(
						`//direct.shopperapproved.com/thankyou/rate/${
							shopper
						}.js?d=${
							date.getTime()}`,
					);
				}
			}
		} catch (ex) {
			// TODO: Analytics AJAX
			// jQuery.ajax({
			// 	type: 'POST',
			// 	url: Globals.ajaxurl,
			// 	data:
			// 		'action=np_ajax&method=timeout&ajax_method=AnalyticsPurchase',
			// });
		}
	}

	/**
	 * Refunds products for a specific transaction
	 *
	 * @param array products
	 * @param string transaction_id
	 */
	public refund(products: any[], transactionId: string) {
		if (this.hasGoogleAnalytics()) {
			if (typeof products !== 'undefined' && products.length > 0) {
				products.forEach((item: any) => {
					ga4('ec:addProduct', {
						id: item.id,
						quantity: 1,
					});
				});
			}

			ga4('ec:setAction', 'refund', { id: transactionId });
			this.actionGoogleAnalytics('UX', 'purchase', transactionId);
		}

		this.actionBingUniversalEventTracker('Refund', transactionId);
		this.actionYahooGemini('Refund', transactionId);
	}

	/**
	 * Records the customers position in reservation quote process
	 */
	public checkout(
		products: any[],
		step: number,
		option: number,
		list: number,
		location: string,
		source: string,
	) {
		try {
			if (this.hasGoogleAnalytics()) {
				products.forEach((item) => {
					ga4('ec:addProduct', {
						id: item.id,
						name: item.name,
						price: item.price,
						category: item.category,
						list,
						quantity: 1,
						brand: location,
						variant: source,
					});
				});

				ga4('ec:setAction', 'checkout', {
					step,
					option,
					list,
				});

				this.actionGoogleAnalytics('UX', 'checkout', step);
			}

			this.actionBingUniversalEventTracker('Checkout', '');
			this.actionYahooGemini('Checkout');

			if (this.hasSiteCatalyst()) {
				site_catalyst.pageName = `${ANALYTICS_SETTINGS.pagename}:reservation:`;
				site_catalyst.pageType = 'info';
				site_catalyst.events = 'scCheckout';
				site_catalyst.prop1 = `${ANALYTICS_SETTINGS.pagename}:reservation`;
				site_catalyst.products = ';parkingType1';
				switch (step) {
					case 3:
						site_catalyst.pageName += 'customer info';
						site_catalyst.t();
						break;
					case 4:
						site_catalyst.pageName = 'payments';
						site_catalyst.t();
						break;
					default:
						break;
				}
			}

			switch (step) {
				case 2:
					this.actionFacebookPixel('InitiateCheckout');
					break;
				case 4:
					this.actionFacebookPixel('AddPaymentInfo');
					break;
				default:
					this.actionFacebookPixel('ViewContent', {
						content_category: 'Checkout',
						content_name: step,
					});
					break;
			}
		} catch (ex) {
			// TODO: Analytics AJAX
			// jQuery.ajax({
			// 	type: 'POST',
			// 	url: Globals.ajaxurl,
			// 	data:
			// 		'action=np_ajax&method=timeout&ajax_method=AnalyticsCheckout',
			// });
		}
	}

	public promo(
		id: string,
		name: string,
		creative: string,
		position: number,
		isClick: boolean | string,
	) {
		if (this.hasGoogleAnalytics()) {
			ga4('ec:addPromo', {
				id,
				name,
				creative,
				position,
			});

			if (isClick) {
				ga4('ec:setAction', 'promo_click');
				this.actionGoogleAnalytics('UX', 'add promo', name);
				return;
			}

			this.actionGoogleAnalytics('UX', 'view promo', name);
		}

		this.actionBingUniversalEventTracker('ViewPromo', '', name);
		this.actionFacebookPixel('Lead', {
			content_category: 'View Promo',
			content_name: name,
			content_ids: [id],
		});
		this.actionYahooGemini('ViewPromo', name);
	}

	public actionFacebookPixel(label: string, value?: any) {
		let type = label;
		let data = value;
		try {
			if (this.hasFacebookPixel()) {
				if (label === 'click') {
					type = 'ViewContent';
				}

				if (value && typeof value !== 'object') {
					data = { content_name: value };
				}

				fbq('track', type, data);
			}
		} catch (exception) {
			/* Failed */
		}
	}

	public expediaConversion(
		conversionId: string,
		transactionId: string,
		total: number,
	) {
		if (!conversionId) {
			return;
		}

		const axel = `${Math.random()}`;
		const a = +axel * 10000000000000;

		document.write(
			`<img
				src="https://pubads.g.doubleclick.net/activity;xsp=${conversionId};qty=1;cost=${total};ord=${transactionId}?"
				width=1
				height=1
				border=0
			/>`,
		);
	}

	public googleConversion(
		conversionId: string,
		conversionLabel: string,
		transactionId?: string,
		total?: number,
		net?: string | number,
		taxes?: number,
	) {
		if (typeof conversionId === 'undefined' || typeof conversionLabel === 'undefined') {
			return;
		}

		try {
			if (typeof gtag !== 'undefined') {
				gtag('event', 'conversion', {
					send_to: `${conversionId}/${conversionLabel}`,
					value: net,
				});
			}

			google_trackConversion({
				google_conversion_id: conversionId,
				google_conversion_label: conversionLabel.toString(),
				google_conversion_value: net,
			});
		} catch (ex) {
			console.log(ex);
			/* Failed */
		}
	}

	public googleSurveyOptIn(
		merchantId: string,
		reservation: Reservation,
	) {
		if (
			typeof merchantId === 'undefined'
		) {
			return;
		}

		try {
			gapi.load('surveyoptin', () => {
				gapi.surveyoptin.render({
					merchant_id: merchantId,
					order_id: reservation.reservation,
					email: reservation.email,
					delivery_country: 'US',
					estimated_delivery_date: format(new Date(reservation.start_date), 'YYYY-MM-DD'),
					opt_in_style: 'BOTTOM_RIGHT_DIALOG',
				});
			});
		} catch (ex) {
			/* Failed */
		}
	}

	protected hasGoogleAnalytics(): boolean {
		const ga4Exists = !(typeof ga4 === 'undefined');

		return ga4Exists;
	}

	protected hasBingUnifiedEventTracking(): boolean {
		return !(typeof uetq === 'undefined');
	}

	protected hasFacebookPixel(): boolean {
		return !(typeof fbq === 'undefined');
	}

	protected hasShopperApproved(): boolean {
		return !(typeof ANALYTICS_SETTINGS.details.shopper === 'undefined');
	}

	protected hasYahooGemini(): boolean {
		return !(typeof dotq === 'undefined');
	}

	protected hasSiteCatalyst(): boolean {
		return !(typeof ANALYTICS_SETTINGS.details.site_catalyst === 'undefined');
	}

	protected hasSojern(): boolean {
		return !(typeof ANALYTICS_SETTINGS.details.sojern === 'undefined' || ANALYTICS_SETTINGS.details.sojern === '');
	}

	protected hasHubspot(): boolean {
		return !(typeof hsq === 'undefined');
	}

	protected actionGoogleAnalytics(
		type: string,
		label: string,
		value: any,
		step?: number | string,
	) {
		try {
			if (this.hasGoogleAnalytics()) {
				ga4('event', label, value);
			}
		} catch (exception) {
			/* Failed */
		}
	}

	protected actionBingUniversalEventTracker(
		label: string,
		value: any,
		goalValue?: number | string,
	) {
		try {
			if (this.hasBingUnifiedEventTracking()) {
				uetq.push({
					ec: 'event',
					ea: label,
					el: value,
					gv: goalValue && !Number.isNaN(+goalValue) ? +goalValue : 0,
				});
			}
		} catch (exception) {
			/* Failed */
		}
	}

	protected actionYahooGemini(
		type: string,
		label?: string | null,
		value?: number | string,
		goalValue?: number | string,
	) {
		try {
			if (this.hasYahooGemini()) {
				dotq.push({
					projectId: ANALYTICS_SETTINGS.gemini_project_id,
					properties: {
						pixelId: ANALYTICS_SETTINGS.gemini_pixel_id,
						qstrings: {
							et: 'custom',
							ec: type,
							ea: label,
							el: label,
							ev: value,
							gv:
								goalValue && !Number.isNaN(+goalValue)
									? +goalValue
									: 0,
						},
					},
				});
			}
		} catch (exception) {
			/* Failed */
		}
	}

	protected actionHubspot(label: string, value?: number | string) {
		try {
			if (this.hasHubspot()) {
				hsq.push([
					'trackEvent',
					{
						id: label,
						value: value || 0,
					},
				]);
			}
		} catch (exception) {
			/* Failed */
		}
	}
}
