import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faAngleDown,
	faAngleRight,
	faBuilding,
	faCalendarAlt,
	faCaretRight,
	faCheckCircle,
	faCheckSquare,
	faCircle,
	faClock,
	faEnvelope,
	faExclamationCircle,
	faEye,
	faEyeSlash,
	faIdCard,
	faInfoCircle,
	faMapMarkerAlt,
	faMobile,
	faPhone,
	faPlusCircle,
	faPlus,
	faSquare,
	faTimesCircle,
	faMinus,
} from '@fortawesome/pro-light-svg-icons';

import {
	faBan as faBanRegular,
	faEnvelope as faEnvelopeRegular,
	faPrint as faPrintRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
	faCaretRight as faCaretRightSolid,
	faCheckCircle as faCheckCircleSolid,
	faCheckSquare as faCheckSquareSolid,
	faChevronCircleRight,
	faCircle as faCircleSolid,
	faDotCircle,
	faInfoCircle as faInfoCircleSolid,
	faMinus as faMinusSolid,
	faPlus as faPlusSolid,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
	// Light
	faAngleDown,
	faAngleRight,
	faBuilding,
	faCalendarAlt,
	faCaretRight,
	faCheckCircle,
	faCheckSquare,
	faCircle,
	faClock,
	faEnvelope,
	faExclamationCircle,
	faEye,
	faEyeSlash,
	faIdCard,
	faInfoCircle,
	faMobile,
	faMapMarkerAlt,
	faPhone,
	faPlusCircle,
	faSquare,
	faTimesCircle,

	// Regular
	faBanRegular,
	faEnvelopeRegular,
	faPrintRegular,

	// Solid
	faCaretRightSolid,
	faCheckCircleSolid,
	faCheckSquareSolid,
	faChevronCircleRight,
	faCircleSolid,
	faDotCircle,
	faInfoCircleSolid,
	faMinusSolid,
	faPlusSolid,
);

Vue.component('FaIcon', FontAwesomeIcon);
