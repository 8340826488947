export interface AddressFields {
	address?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	zip?: string;
	phone?: string;
	email?: string;

	[key: string]: any;
}

export function FormatPhone(value: string | number): string {
	if (!value) {
		return '';
	}

	let phone = (`${value}`).replace(/([^0-9x]+)/g, '');
	let extension = null;

	if (phone.indexOf('x') !== -1) {
		extension = phone.substr(phone.indexOf('x'));
		phone = phone.substr(0, phone.indexOf('x'));
	}

	let formatted = phone;
	if (phone.length === 10) {
		formatted = `1 (${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
	} else if (phone.length === 11) {
		formatted = `${phone.substr(0, 1)} (${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7, 4)}`;
	} else if (phone.length === 7) {
		formatted = `${phone.substr(4, 3)}-${phone.substr(7, 4)}`;
	}

	// Add the extension to the end as " x123"
	if (extension) {
		formatted += ` ${extension}`;
	}

	return formatted || '';
}

export function FormatAddress(addressFields: AddressFields, separator: string = '<br />'): string {
	return Object.keys(addressFields)
		.filter((field) => ['address', 'address1', 'address2', 'city', 'state', 'zip', 'phone', 'email'].indexOf(field) >= 0)
		.reduce((carry: string, item: string) => {
			let address = carry;

			if (!addressFields[item]) {
				return address;
			}

			if (address && ['state', 'zip', 'country'].indexOf(item) === -1 && addressFields[item]) {
				address += separator;
			} else if (addressFields.city && item === 'state') {
				address += ', ';
			} else if (addressFields.city && !addressFields.state && item === 'zip') {
				address += ', ';
			} else if (addressFields.state && item === 'zip') {
				address += ' ';
			} else if ((addressFields.city || addressFields.state || addressFields.zip) && item === 'country') {
				address += ' ';
			}

			const value = (`${addressFields[item]}`).replace(/</g, '&lt;').replace(/>/g, '&gt;');

			if (item === 'phone') {
				address += `<a href="tel:${value}">${FormatPhone(value as string | number)}</a>`;
			} else if (item === 'email') {
				address += `<a href="mailto:${value}">${value}</a>`;
			} else {
				address += value || '';
			}

			return address;
		},
		'');
}
