// Axios configuration
import axios from 'axios';

// Vue Plugins
import PortalVue from 'portal-vue';
import VeeValidate, { Configuration } from 'vee-validate';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

// Vue global components
import LocationSelector from '@/components/LocationSelector.vue';
import NpAlert from '@/components/NpAlert.vue';
import NpMultiselect from '@/components/NpMultiselect.vue';
import AppInput from '@/components/ui/AppInput.vue';

// Vue filters
import '@/filters/capitalize';
import '@/filters/cardNumber';
import '@/filters/currency';
import '@/filters/date-time';
import '@/filters/phone';
import '@/filters/trim-phone';
import '@/filters/truncate';

// Nav menu handling
import '@/utilities/NavMenu';

// Icons
import './icons';
import store from './store';

// Style
import './styles/app.less';

// Set dynamic Webpack public path.
__webpack_public_path__ = `${NP_PLUGIN_URL}dist/`;

axios.defaults.headers.common['X-CSRF-TOKEN'] = _wpnonce;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response ? error.response.status : null;
		const message = error.response ? error.response.data : null;

		if (status === 403 && message === 'CSRF Token not validated.') {
			window.location.reload();
		}

		return Promise.reject(error);
	},
);

Vue.use(PortalVue);
Vue.use(VTooltip, {
	defaultClass: 'np-tooltip-theme',
	defaultTargetClass: 'np-has-tooltip',
	defaultTemplate:
		`<div class="np-tooltip" role="tooltip">
			<div class="np-tooltip-arrow"></div>
			<div class="np-tooltip-inner"></div>
		</div>`,
	defaultArrowSelector: '.np-tooltip-arrow, .np-tooltip__arrow',
	defaultInnerSelector: '.np-tooltip-inner, .np-tooltip__inner',
	defaultLoadingClass: 'np-tooltip-loading',
	popover: {
		defaultClass: 'np-popover-theme',
		defaultBaseClass: 'np-tooltip np-popover',
		defaultWrapperClass: 'np-tooltip-wrapper',
		defaultInnerClass: 'np-tooltip-inner np-popover-inner',
		defaultArrowClass: 'np-tooltip-arrow np-popover-arrow',
	},
});

Vue.use(VeeValidate, {
	classes: true,
	classNames: {
		touched: 'np-input--touched', // the control has been blurred
		untouched: 'np-input--untouched', // the control hasn't been blurred
		valid: 'np-input--valid', // model is valid
		invalid: 'np-input--invalid', // model is invalid
		pristine: 'np-input--pristine', // control has not been interacted with
		dirty: 'np-input--dirty', // control has been interacted with
	},
	inject: false,
	events: 'blur|change|changed|on-close',
} as Configuration);
Vue.use(VModal, { componentName: 'NpModal' });
Vue.use(Vuelidate);

Vue.component('LocationSelector', LocationSelector);
Vue.component('NpAlert', NpAlert);
Vue.component('NpMultiselect', NpMultiselect);
Vue.component('AppInput', AppInput);

Vue.config.productionTip = false;

function hasVueAncestor(el: HTMLElement): boolean {
	const parent = el.parentElement;

	if (!parent) {
		return false;
	}

	if (parent.getAttribute('id') === 'app' || parent.getAttribute('data-vue')) {
		return true;
	}

	return hasVueAncestor(parent);
}

// Initialize Vue
const appElements = document.querySelectorAll('#app, [data-vue=true]');

Array.from(appElements).forEach((el) => {
	if (hasVueAncestor(el as HTMLElement)) {
		return;
	}

	new Vue({
		store,
		components: {
			Locations: () => import('./components/NpLocations.vue'),
			TheLoginPage: () => import('./components/TheLoginPage.vue'),
			TheProfilePage: () => import('./components/TheProfilePage.vue'),
			TheRecurringPage: () => import('./components/TheRecurringPage.vue'),
			TheRegisterPage: () => import('./components/TheRegisterPage.vue'),
			TheReservationBookPage: () => import('./components/TheReservationBookPage.vue'),
			TheReservationEditPage: () => import('./components/TheReservationEditPage.vue'),
			TheReservationLookupPage: () => import('./components/lookup/TheReservationLookupPage.vue'),
			TheResetPasswordPage: () => import('./components/TheResetPasswordPage.vue'),
			TheSurveyPage: () => import('./components/TheSurveyPage.vue'),
			RecurringOptions: () => import('./components/RecurringOptions.vue'),
			LoginModal: () => import('@/components/LoginModal.vue'),
			DatesWidget: () => import('@/components/DatesWidget.vue'),
		},
		created() {
			const url = new URL(window.location.href);
			let location = url.searchParams.get('loc');
			if (location && location.split('-').length <= 1) {
				location = (window as any).activeLocation;
			}

			if (location) {
				this.$store.dispatch('location/setLocationFromCode', location);
			}
		},
	}).$mount(el);
});
