
enum ReservationStep {
	'Dates',
    'Quotes',
    'Customer',
    'Services',
    'Payment',
    'Summary',
    'Confirmation',
}

export default ReservationStep;
