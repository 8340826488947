import Model from '@/models/Model';
import PaymentMethod from '@/models/PaymentMethod';
import { checkTrue } from '@/utilities/helpers';

export default class ACHAccount extends Model implements PaymentMethod {
	public company_id: null | number = null;
	public customer_id: null | number = null;
	public customer_token: string = '';
	public created_at: Date | string = new Date();
	public deleted: boolean = false;
	public expiration: string = '';
	public id: number = 0;
	public is_new: boolean = false;
	public location: number = 1;
	public name: string = '';
	public new: boolean;
	public number: number | string = 0;
	public primary: boolean = true;
	public token: string = '';
	public type: string = 'ACH';

	public get payment_type(): string {
		return 'ach';
	}

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		this.new = checkTrue(data);
	}
}
