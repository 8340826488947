




























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
	props: {
		additionalClasses: {
			type: [String, Array],
			default: '',
		},
		close: {
			type: Boolean,
			default: true,
		},
		icon: {
			type: Array,
			default: null,
		},
		show: {
			type: Boolean,
			default: true,
		},
		timeout: {
			type: Number,
			default: 0,
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				return ['', 'primary', 'secondary', 'danger', 'warning', 'success'].indexOf(value) >= 0;
			},
		},
	},
	watch: {
		show(newValue) {
			(this as NpAlert).showing = newValue;
			if (newValue) {
				(this as NpAlert).startTimer();
			}
		},
	},
})
export default class NpAlert extends Vue {
	// Props
	public additionalClasses!: string | string[];
	public icon!: [];
	public timeout!: number;
	public show!: boolean;
	public type!: string;

	// Data
	public showing: boolean = this.show;

	public get classes(): string {
		let classes = `np-alert ${this.type && `np-alert--${this.type}`}`;

		if (this.additionalClasses.constructor === Array) {
			classes = `${classes} ${(this.additionalClasses as string[]).join(
				' ',
			)}`;
		} else {
			classes = `${classes} ${this.additionalClasses}`;
		}

		return classes;
	}

	public mounted() {
		this.startTimer();
	}

	public closeAlert() {
		this.showing = false;
		this.$emit('update:show', false);
		this.$emit('dismissed', true);
	}

	public startTimer() {
		if (this.timeout > 0) {
			window.setTimeout(() => {
				this.closeAlert();
			}, this.timeout * 1000);
		}
	}
}
