import { getTypeInfo } from 'credit-card-type';
import Vue from 'vue';
import { types } from '@/utilities/paymentInformation';
import { padCharsStart } from '@/utilities/helpers';

Vue.filter(
	'cardNumber',
	(cardNumber: string, cardType: string): string => {
		const card = getTypeInfo(types[cardType]);

		if (card) {
			cardNumber = padCharsStart('X')(card.lengths[0])(cardNumber);
			const offsets = ([] as any[]).concat(0, card.gaps, cardNumber.length);
			const components = [];

			for (let i = 0; offsets[i] < cardNumber.length; i += 1) {
				const start = offsets[i];
				const end = Math.min(offsets[i + 1], cardNumber.length);
				components.push(cardNumber.substring(start, end));
			}

			return components.join(' ');
		}

		return String(cardNumber);
	},
);
