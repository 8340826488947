import { addMinutes } from 'date-fns';

export function getCookie(cname: string) {
	const name = `${cname}=`;
	const cookieData = document.cookie.split(';');

	let value = '';

	cookieData.some((item: string) => {
		const search = item.trim();

		if (search.indexOf(name) === 0) {
			value = search.substr(name.length, search.length);

			return true;
		}

		return false;
	});

	return value;
}

export function setCookie(cname: string, cvalue: number | string, minutes: number) {
	const expires = `expires=${addMinutes(new Date(), minutes).toUTCString()}`;
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
}
