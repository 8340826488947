import CreditCard from '@/models/CreditCard';
import {
	getOrZero, getOrNull, getOrString,
} from '@/utilities/helpers';

export default class Payment {
	public auto_key: number | null = 0;
	public ach_transaction_id: number;
	public amount: number;
	public card: CreditCard;
	public category: null | number;
	public company_id: null | number;
	public customer_id: null | number;
	public id: number | null = 0;
	public notes: string;
	public type: string;

	public constructor(data: any = {}) {
		this.auto_key = getOrZero('auto_key', data);
		this.ach_transaction_id = getOrZero('ach_transaction_id', data);
		this.amount = getOrZero('amount', data);
		this.card = new CreditCard(data.card);
		this.category = getOrNull('category', data);
		this.company_id = getOrNull('company_id', data);
		this.customer_id = getOrNull('customer_id', data);
		this.id = getOrZero('id', data);
		this.notes = getOrString('notes', data);
		this.type = getOrString('type', data);
	}

	public setReservationAmounts(data: any) {
		const name = `${getOrString('first_name', data)} ${getOrString('last_name', data)}`;
		this.amount = getOrZero('quote.prepay_amount', data);
		this.customer_id = getOrZero('customer_id', data);
		this.notes = `Charge for reservation. Booked by: ${name}`;
	}
}
