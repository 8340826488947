import Vue from 'vue';

Vue.filter('currency', (value: number | string, currency: string, decimals: number) => {
	value = parseFloat(String(value)) || 0;
	decimals = decimals !== 0 && decimals ? decimals : 2;
	if (!Number.isFinite(value) || (!value && value !== 0)) {
		return '';
	}
	currency = currency != null ? currency : '$';
	const stringified = Math.abs(value).toFixed(decimals);
	const int = stringified.slice(0, -1 - decimals);
	const i = int.length % 3;
	const head = i > 0
		? (int.slice(0, i) + (int.length > 3 ? ',' : ''))
		: '';
	const float = stringified.slice(-1 - decimals);
	const sign = value < 0 ? '-' : '';
	return sign + currency + head + int.slice(i).replace('[0-9]', '$1,') + float;
});
