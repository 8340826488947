import Vue from 'vue';
import { ActionContext } from 'vuex';
import { getOrArray } from '@/utilities/helpers';

/**
 * Mutation Helpers
 */

/**
 * Push an item onto an array.
 *
 * @param key
 */
export const pushTo = (key: string) => (state: any, val: any) => {
	const array = getOrArray(key, state);
	array.push(val);
};

export const set = (key: string) => (state: any, value: any) => {
	Vue.set(state, key, value);
};

export const setModelArray = (key: string, Model: any) => (
	state: any,
	value: any,
) => {
	Vue.set(
		state,
		key,
		(Array.isArray(value) ? value : []).map((item: any) => new Model(item)),
	);
};

/**
 * Action Helpers
 */
export const service = (
	serviceInstance: any,
	method: string,
	commit?: string,
	action?: string,
) => (context: ActionContext<any, any>, payload: any) => new Promise((resolve, reject) => {
	context.dispatch('startRequest', true, { root: true });

	serviceInstance[method](payload)
		.then((response: any) => {
			context.dispatch('successfulRequest', response, { root: true });

			if (commit) {
				context.commit(commit, response);
			}

			if (action) {
				context.dispatch(action, response);
			}

			return resolve(response);
		})
		.catch((errors: any) => {
			context.dispatch('failedRequest', errors, { root: true });

			return reject(errors);
		});
});

export const setFind = (comparator: string, searchTarget: string, mutation: string, type: string = 'state') => (
	context: ActionContext<any, any>,
	value: any,
) => {
	const search = type === 'state' ? context.state : context.getters;

	const foundValue = search[searchTarget].find(
		(item: any) => item[comparator] === value,
	);

	context.commit(mutation, foundValue);
};

export const sleep = async (time: number): Promise<void> => new Promise((resolve) => {
	setTimeout(resolve, time)
})
