import { addMinutes } from 'date-fns';
import {
	ActionTree,
	GetterTree,
	mapGetters,
	Module,
	MutationTree,
} from 'vuex';
import ParkingZone from '@/models/ParkingZone';
import RecurringGroup from '@/models/RecurringGroup';
import {
	get, getOr, getOrObject, getOrString, has, isEmpty, mapModels,
} from '@/utilities/helpers';

import locationService from '@/services/LocationService';
import { FormatAddress } from '@/utilities/formatters';
import { getCookie, setCookie } from '@/utilities/cookie';
import { service, set, setFind } from '@/utilities/vuexHelpers';

const data = NP_PLUGIN_DATA || {};

interface State {
	location: Locations.Location;
	locations: Locations.Location[];
	token: string;
}

let activeLocation = {} as Locations.Location;

// Set the active location on the state if we have one from the backend
if ((window as any).activeLocation) {
	const locationCode = (window as any).activeLocation as string;

	activeLocation = data.location.locations.find((location: Locations.Location) => location.codeID === locationCode)
	|| {} as Locations.Location;
}

const State: State = {
	location: activeLocation,
	locations: data.location.locations as Locations.Location[],
	token: '',
};

const Getters: GetterTree<State, StoreTypes.RootState> = {
	address(state, getters): string {
		return FormatAddress({
			address: get('location.details.address', getters),
			city: get('location.details.city', getters),
			state: get('location.details.state', getters),
			zip: get('location.details.zip', getters),
			country: get('location.details.country', getters),
		});
	},

	allParkingZones(state, getters): ParkingZone[] {
		let parkingZones: ParkingZone[] = [];

		getters.subLocations.forEach((location: Locations.Location) => {
			if (Array.isArray(location.parkingZones)) {
				parkingZones = parkingZones.concat((location.parkingZones || []).map((zone: ParkingZone) => new ParkingZone(zone)));
			}
		});

		return parkingZones;
	},

	allRecurringGroups(state, getters): RecurringGroup[] {
		let recurringGroups: RecurringGroup[] = [];

		getters.subLocations.forEach((location: Locations.Location) => {
			if (Array.isArray(location.recurringGroups)) {
				recurringGroups = recurringGroups.concat((location.recurringGroups || []).map((group: RecurringGroup) => new RecurringGroup(group)));
			}
		});

		return recurringGroups;
	},

	corporateCompany(state, getters, rootState, rootGetters) {
		const { code = 0 } = getters.location as Locations.Location;
		const { company_locations = [] } = rootGetters.corporation as App.Corporation;

		return company_locations.find((item) => +code === +item.code);
	},

	corporatePromo(state, getters, rootState, rootGetters): boolean {
		const { codeID = '' } = getters.location as Locations.Location;
		const { promo_locations = [] } = rootGetters.corporation as App.Corporation;

		const corporateLocation = promo_locations.find((item) => codeID === item.codeID);

		return (corporateLocation && corporateLocation.has_promo) || false;
	},

	customerLocations(state): Locations.Location[] {
		const locations = state.locations.reduce((carry, location) => {
			if (carry[location.custLocation as string]) {
				return carry;
			}

			if (!location.settings || location.settings.disable_customers) {
				return carry;
			}

			if (!location.settings.registration_types) {
				return carry;
			}

			carry[location.custLocation as string] = location;

			return carry;
		}, {} as Locations.Location);

		return Object.keys(locations).map((key) => locations[key]);
	},

	details(state, getters): Locations.Details {
		return getters.location.details;
	},

	email(state, getters): string {
		return getters.settings.contact_email || getters.details.email;
	},

	hasAch(state, getters): boolean {
		return getters.settings.has_ach;
	},

	hasLocation(state, getters): boolean {
		return !isEmpty(get('location', getters));
	},

	location(state): Locations.Location {
		if (state.location && state.location.codeID) {
			return state.location;
		}

		if (state.locations.length === 1) {
			return state.locations[0];
		}

		const locationCode = (window as any).activeLocation as string;

		return state.locations.find((location: Locations.Location) => location.codeID === locationCode)
			|| {} as Locations.Location;
	},

	locations(state): Locations.Location[] {
		return state.locations;
	},

	locationCodeID(state, getters): string {
		return getOrString('codeID', getters.location);
	},

	locationName(state, getters): string {
		return getOrString('name', getters.location.details);
	},

	locationNumber(state, getters): string | number {
		return getOrString('code', getters.location) as string | number;
	},

	parkingZones(state, getters): ParkingZone[] {
		return (getters.location.parkingZones || []).map((zone: ParkingZone) => new ParkingZone(zone));
	},

	primaryPhone(state, getters): string {
		return getters.settings.primary_phone_description || 'Cell Phone';
	},

	recurringGroups(state, getters): RecurringGroup[] {
		return (getters.location.recurringGroups || []).map((group: RecurringGroup) => new RecurringGroup(group));
	},

	recurringLocations(state, getters): Locations.Location[] {
		return state.locations.filter((location: Locations.Location) => ((location.parkingZones || []).length + (location.recurringGroups || []).length) > 0);
	},

	recurringOptions(state, getters): Array<ParkingZone | RecurringGroup> {
		return [...getters.parkingZones, ...getters.recurringGroups]
			.sort((a: any, b: any) => (a.name > b.name ? 1 : 0));
	},

	registrationTypes(state, getters): Locations.RegistrationType[] {
		return getters.settings.registration_types || [];
	},

	reservationLocations(state): Locations.Location[] {
		return state.locations.filter((location: Locations.Location) => location.settings && !location.settings.disable_reservations);
	},

	secondaryPhone(state, getters): string {
		return getters.settings.secondary_phone_description || 'Work Phone';
	},

	settings(state, getters): Locations.Settings {
		return getOrObject('settings', getters.location) as Locations.Settings;
	},

	source(state, getters): string {
		return getOrString('reservation.source', getters.location);
	},

	subLocations(state, getters): Locations.Location[] {
		return state.locations.filter((location) => location.code === getters.locationNumber);
	},

	subscriptionAvailable(state, getters): boolean {
		return (has('marketing.responsys_username', getters.location)
				&& has('marketing.responsys_password', getters.location)
				&& has('marketing.responsys_newsletter_list', getters.location))
			|| (has('marketing.hubspot_api', getters.location)
				&& has('marketing.hubspot_newsletter_list', getters.location));
	},
};

const Mutations: MutationTree<State> = {
	clearLocation(state: State) {
		state.location = {} as Locations.Location;
		setCookie('location', '', -1);
	},

	setToken: set('token'),
	setLocation: set('location'),
	setLocations: set('locations'),
};

const Actions: ActionTree<State, StoreTypes.RootState> = {
	fetchToken: service(locationService, 'fetchToken', 'setToken'),

	setLocation({ dispatch, rootState }, locationCode: number | string) {
		const { paths } = rootState;

		setCookie('location', locationCode, 30);
		dispatch('setLocationFromCode', locationCode);
	},

	setLocationFromCode: setFind('codeID', 'locations', 'setLocation'),

	// set multiple locations here
	// setLocations({ dispatch, rootState }, locationCodes: []) {
	// 	let locations_data = "";
	// 	locationCodes.forEach(location => {
	// 		if(location.codeID) {
	// 			if (locations_data == "") {
	// 				locations_data = location.codeID;
	// 			} else {
	// 				locations_data = locations_data+", "+location.codeID;
	// 			}
	// 		}
	// 	});
	// 	setCookie('locations', locations_data, 30);
	// 	dispatch('setLocationFromCode', locations_data);
	// },
};

export default {
	namespaced: true,
	state: State,
	getters: Getters,
	mutations: Mutations,
	actions: Actions,
} as Module<State, StoreTypes.RootState>;
